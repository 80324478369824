
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      Template7.registerPartial('select-airwaybill', '<div class="popup popup-select-airwaybill">    <div class="page">      <div class="navbar">        <div class="navbar-bg"></div>        <div class="navbar-inner">          <div class="left"><a href="#" class="link popup-close">Close</a></div>          <div class="title">Select Airway Bill</div>        </div>      </div>      <div class="page-content">        <div class="list media-list">          <ul>            {{#each search_results}}            <li class="purchase-item swipeout">              <div class="swipeout-content">                <a @click="OpenAirwaybill(\'{{ airwaybillno }}\')" class="item-link item-content">                  <div class="item-media">                    <img src="{{ stockimage }}?1" width="80" />                  </div>                  <div class="item-inner">                    <div class="item-title-row">                      <div class="item-title">{{ title }}</div>                      <div class="item-after">{{ qty }}</div>                    </div>                    <div class="item-subtitle">                      {{ sku }}                    </div>                    <div class="item-subtitle">                      {{#js_if "parseInt(this.purchaseorderid) > 0 "}}                      <span class="order-purchase" id="{{ purchaseorderid }}">WS Order Id: {{ purchaseorderid }}</span>                      {{else}}                      <span class="advance-purchase">Advance Purchase</span>                      {{/js_if}}                    </div>                    <div class="item-text">AWB: {{ airwaybillno }}</div>                    <div class="item-text">TrackingId: {{ trackingno }}</div>                  </div>                </a>              </div>            </li>            {{/each}}          </ul>        </div>      </div>    </div>  </div>');
  
      export default {
  // Lifecycle Hooks
  beforeCreate() {
    console.log("componentBeforeCreate", this);
  },

  created() {
    console.log("componentCreated", this);
  },

  beforeMount() {
    console.log("componentBeforeMount", this);
  },

  mounted() {
    console.log("componentMounted", this);
    const self = this;
    const app = self.$app;
    const $ = self.$$;
    const router = self.$router;
    $(document).on("keyup touchend select", ".selradioinput", function () {
      let id = $(this).attr("id");
      let radio_id = `#${id}_radio`; // console.log('id', id);
      // console.log('radio_id', radio_id);

      $(radio_id).attr("checked", true);
    });
    $("input").on("keydown", function (e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        self.findRadioSelection();
      }
    });
  },

  beforeDestroy() {
    console.log("componentBeforeDestroy", this);
  },

  destroyed() {
    console.log("componentDestroyed", this);
  },

  // Component Data
  data: function () {
    // Must return an object
    return {
      search_results: [],
      barcodeTrackingNo: "",
      barcodeTrackingNoCharLen: 8
    };
  },
  // Component Methods
  methods: {
    ShowScanner: function () {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$router;
      app.data.isBarcodeScannerOn = true;
      cordova.plugins.barcodeScanner.scan(function (result) {
        if (result.cancelled !== true) {
          app.data.isBarcodeScannerOn = false;
        }

        if (result.format.includes("CODE_39")) {
          self.OpenAirwaybill(result.text.trim());
        } else if (result.format.includes("UPC")) {
          self.FindAWBBarcodeUPC(result.text.trim());
        } else {
          if (result.text) {
            let dashIndex = result.text.lastIndexOf("-");
            let barcodeTrackingNo;

            if (dashIndex === -1) {
              barcodeTrackingNo = result.text;
            } else {
              barcodeTrackingNo = result.text.substring(0, dashIndex);
            }

            self.FindAwbBarcodeScan(barcodeTrackingNo, 8);
          }
        } // app.dialog.alert("We got a barcode\n" +
        //   "Result: " + result.text + "\n" +
        //   "Format: " + result.format + "\n" +
        //   "Cancelled: " + result.cancelled);

      }, function (error) {
        // app.data.isBarcodeScannerOn = false;
        app.dialog.alert("Scanning failed: " + error);
      }, {
        preferFrontCamera: false,
        // iOS and Android
        showFlipCameraButton: false,
        // iOS and Android
        showTorchButton: true,
        // iOS and Android
        torchOn: false,
        // Android, launch with the torch switched on (if available)
        saveHistory: false,
        // Android, save scan history (default false)
        prompt: "Place a barcode inside the scan area",
        // Android
        resultDisplayDuration: 500,
        // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
        formats: "QR_CODE,CODE_128,UPC_A,UPC_E,EAN_8,EAN_13,CODE_39",
        // default: all but PDF_417 and
        // orientation: "portrait", // Android only (portrait|landscape), default unset so it rotates with the device
        disableAnimations: true,
        // iOS
        disableSuccessBeep: true,
        // iOS and Android
        continuousMode: false // Android

      });
    },
    FindAWBBarcodeUPC: function (upc) {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$router;
      let postdata = {
        remoteSection: "ims",
        w: "FindAirwayBillsV2",
        keywords: upc
      };
      app.dialog.preloader("Finding Airway Bills");
      app.request.promise({
        url: app.data.apiUrl,
        data: postdata,
        method: "POST",
        dataType: "json",
        headers: {
          Authorization: app.data.ifs_token
        },
        timeout: app.data.defaultTimeout
      }).then(function ({
        data,
        xhr,
        status
      }) {
        app.dialog.close();

        if (data.result == 1) {
          let json = JSON.parse(data.json);

          if (json.length == 1) {
            router.navigate(`/awbinward/${json[0].airwaybillno}`, {
              ignoreCache: true
            });
          } else {
            self.$setState({
              search_results: json
            });
            self.$tick(() => {
              let popup = app.popup.create({
                content: $(".popup-select-airwaybill"),
                on: {
                  opened: function () {},
                  closed: function () {
                    self.$setState({
                      search_results: []
                    });
                  }
                }
              });
              popup.open(true);
            });
          }
        }
      }).catch(function (error) {
        app.dialog.close();
        app.dialog.alert("AJAX Error: " + error.message);
      });
    },
    FindAwbBarcodeScan: function (barcodeTrackingNo, barcodeTrackingNoCharLen) {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$router;
      let postdata = {
        remoteSection: "ims",
        w: "FindAirwaybillNo",
        tracking_no: barcodeTrackingNo.substring(barcodeTrackingNo.length - barcodeTrackingNoCharLen)
      };
      app.dialog.preloader("Finding Airway Bills");
      app.request.promise({
        url: app.data.apiUrl,
        data: postdata,
        method: "POST",
        dataType: "json",
        headers: {
          Authorization: app.data.ifs_token
        },
        timeout: app.data.defaultTimeout
      }).then(function ({
        data,
        xhr,
        status
      }) {
        app.dialog.close();

        if (data.result == 1) {
          if (data.airwaybills.length == 1) {
            router.navigate(`/awbinward/${data.airwaybills[0]}`, {
              ignoreCache: true
            });
          } else {
            barcodeTrackingNoCharLen++;

            if (barcodeTrackingNoCharLen >= 12) {
              app.dialog.alert("Too many results, Try a longer Tracking No.. ");
            } else {
              self.FindAwbBarcodeScan(barcodeTrackingNo, barcodeTrackingNoCharLen);
            }
          }
        }
      }).catch(function (error) {
        app.dialog.close();
        app.dialog.alert("AJAX Error: " + error.message);
      });
    },
    OpenAirwaybill: function (airwaybillno) {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$router;
      app.popup.close();
      router.navigate(`/awbinward/${airwaybillno}`, {
        ignoreCache: true
      });
    },
    findRadioSelection: function () {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$router;

      if ($("input[type='radio'][name='find-type-radio']:checked").length) {
        let radioval = $("input[type='radio'][name='find-type-radio']:checked").val();
        let inputval = $(`#${radioval}`).val().trim(); // checks

        if (!inputval) {
          app.dialog.alert(`Input field empty...`, "Error");
          return;
        } //ajax calls


        if (radioval == "awb") {
          // check if the awb exists and count == 1
          router.navigate(`/awbinward/${inputval}`, {
            ignoreCache: true
          });
        } //ajax calls


        if (radioval == "tracking") {
          // check if the tracking exists and count == 1
          let postdata = {
            remoteSection: "ims",
            w: "FindAirwaybillNo",
            tracking_no: inputval
          };
          app.dialog.preloader("Finding Airway Bills");
          app.request.promise({
            url: app.data.apiUrl,
            data: postdata,
            method: "POST",
            dataType: "json",
            headers: {
              Authorization: app.data.ifs_token
            },
            timeout: app.data.defaultTimeout
          }).then(function ({
            data,
            xhr,
            status
          }) {
            app.dialog.close();

            if (data.result == 1) {
              if (data.airwaybills.length == 1) {
                router.navigate(`/awbinward/${data.airwaybills[0]}`, {
                  ignoreCache: true
                });
              } else {
                app.dialog.alert("Too many results, Try a longer Tracking No.. ");
              }
            }
          }).catch(function (error) {
            app.dialog.close();
            app.dialog.alert("AJAX Error: " + error.message);
          });
        }

        if (radioval == "find") {
          if (inputval.length < 3) {
            app.dialog.alert(`Input value too short...`, "Error");
            return;
          }

          let postdata = {
            remoteSection: "ims",
            w: "FindAirwayBillsV2",
            keywords: inputval
          };
          app.dialog.preloader("Finding Airway Bills");
          app.request.promise({
            url: app.data.apiUrl,
            data: postdata,
            method: "POST",
            dataType: "json",
            headers: {
              Authorization: app.data.ifs_token
            },
            timeout: app.data.defaultTimeout
          }).then(function ({
            data,
            xhr,
            status
          }) {
            app.dialog.close();

            if (data.result == 1) {
              let json = JSON.parse(data.json);

              if (json.length == 1) {
                router.navigate(`/awbinward/${json[0].airwaybillno}`, {
                  ignoreCache: true
                });
              } else {
                self.$setState({
                  search_results: json
                });
                self.$tick(() => {
                  let popup = app.popup.create({
                    content: $(".popup-select-airwaybill"),
                    on: {
                      opened: function () {},
                      closed: function () {
                        self.$setState({
                          search_results: []
                        });
                      }
                    }
                  });
                  popup.open(true);
                });
              }
            }
          }).catch(function (error) {
            app.dialog.close();
            app.dialog.alert("AJAX Error: " + error.message);
          });
        } // app.dialog.alert(`${radioval} Selected,  Value: ${inputval}`, 'Error')

      } else {
        app.dialog.alert("Select a valid option.", "Error");
        return;
      }
    }
  },
  // Page Events
  on: {
    pageMounted: function (e, page) {
      console.log("pageMounted", page);
    },
    pageInit: function (e, page) {
      console.log("pageInit", page);
    },
    pageBeforeIn: function (e, page) {
      console.log("pageBeforeIn", page);
    },
    pageAfterIn: function (e, page) {
      console.log("pageAfterIn", page);
    },
    pageBeforeOut: function (e, page) {
      console.log("pageBeforeOut", page);
    },
    pageAfterOut: function (e, page) {
      console.log("pageAfterOut", page);
    },
    pageBeforeRemove: function (e, page) {
      console.log("pageBeforeRemove", page);
    }
  },
  id: 'ef75651a2d',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=about><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Back</span></a></div><div class=title>Products Inward</div><div class=right><a class=link @click=findRadioSelection>Find</a></div></div></div>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.isCordova, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="fab fab-extended fab-right-bottom color-red"><a @click=ShowScanner><i class="icon f7-icons size-32">barcode</i></a></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<div class=page-content><div class="list media-list"><ul><li><label class="item-radio item-content"><input type=radio name=find-type-radio id=awb_radio value=awb> <i class="icon icon-radio"></i><div class=item-inner><div class="item-title item-label">Airwaybill No</div><div class=item-input-wrap><input class=selradioinput type=text id=awb placeholder=9573572210> <span class=input-clear-button></span></div></div></label></li><li><label class="item-radio item-content"><input type=radio name=find-type-radio id=tracking_radio value=tracking> <i class="icon icon-radio"></i><div class=item-inner><div class="item-title item-label">Tracking No</div><div class=item-input-wrap><input class=selradioinput type=text id=tracking placeholder=LS1112876868989> <span class=input-clear-button></span></div></div></label></li><li><label class="item-radio item-content"><input type=radio name=find-type-radio id=find_radio value=find> <i class="icon icon-radio"></i><div class=item-inner><div class="item-title item-label">Find Airwaybill No</div><div class=item-input-wrap><input class=selradioinput type=text id=find placeholder="Fish Oil, etc"> <span class=input-clear-button></span></div></div></label></li></ul></div></div>';
      r += Template7Helpers._partial.call(ctx_1, "select-airwaybill", {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    