
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeMount() {
    const self = this;
    const app = self.$app;
    const $ = self.$$;
    const router = self.$router; // app.dialog.alert(app.data.ifs_token)
    // console.log(app.data)s

    if (app.data.ifs_token == "null" || app.data.ifs_token == null) {
      app.loginScreen.open("#my-login-screen");
    }

    let timestamp = Date.now() / 1000 | 0; // app.dialog.alert(app.data.ifs_token_expiry.toString() + ' ts: ' + timestamp)

    if (app.data.ifs_token_expiry == 0 || parseInt(timestamp) > app.data.ifs_token_expiry) {
      app.loginScreen.open("#my-login-screen");
    }
  },

  id: 'c25014a7a0',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=home><!-- Top Navbar --><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=left><a href=# class="link icon-only panel-open" data-panel=left><i class="icon f7-icons if-not-md">menu</i> <i class="icon material-icons if-md">menu</i></a></div><div class="title sliding">IMS</div><!-- <div class="right">\n          <a href="#" class="link icon-only panel-open" data-panel="right">\n            <i class="icon f7-icons if-not-md">menu</i>\n            <i class="icon material-icons if-md">menu</i>\n          </a>\n        </div> --><div class=title-large><div class=title-large-text>IMS</div></div></div></div><!-- Toolbar--><!-- <div class="toolbar toolbar-bottom">\n      <div class="toolbar-inner">\n        <a href="#" class="link">Left Link</a>\n        <a href="#" class="link">Right Link</a>\n      </div>\n    </div> --><!-- Scrollable page content--><div class=page-content><div class=list><ul><li><a href=/instapick/all class="item-content item-link"><div class=item-inner><div class=item-title>Instapick</div></div></a></li><li><a href=/startinward/ class="item-content item-link"><div class=item-inner><div class=item-title>Purchase Inward</div></div></a></li>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.isCordova, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><a href=/showbarcodes/ class="item-content item-link"><div class=item-inner><div class=item-title>Get Barcodes</div></div></a></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<li><a href=/rackcheckselect/ class="item-content item-link"><div class=item-inner><div class=item-title>Rack Check</div></div></a></li><li><a href=/rackfind/ class="item-content item-link"><div class=item-inner><div class=item-title>Rack Find</div></div></a></li><li><a href=/rackshift/ class="item-content item-link"><div class=item-inner><div class=item-title>Rack Shift</div></div></a></li><li><a href=/awbscan/ class="item-content item-link"><div class=item-inner><div class=item-title>Receive/Scan AWB</div></div></a></li>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.isCordova, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><a href=/snapper/ class="item-content item-link"><div class=item-inner><div class=item-title>Shipment Photos</div></div></a></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<li><a href=/returntorack/ class="item-content item-link"><div class=item-inner><div class=item-title>Return To Rack</div></div></a></li>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.isCordova, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><a href=/viewshipmentimages/ class="item-content item-link"><div class=item-inner><div class=item-title>View Shipment Images</div></div></a></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<li><a href=/shipmentcountcheck/ class="item-content item-link"><div class=item-inner><div class=item-title>Shipment Count Check</div></div></a></li><li><a href=/returnvideos/ class="item-content item-link"><div class=item-inner><div class=item-title>Return Videos</div></div></a></li><li><a href=/labelimages/ class="item-content item-link"><div class=item-inner><div class=item-title>Shipping Label Images</div></div></a></li><!-- <li>\n            <a href="/awbinward/957339959" class="item-content item-link">\n              <div class="item-inner">\n                <div class="item-title">Awb Inward</div>\n              </div>\n            </a>\n          </li> --></ul></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    