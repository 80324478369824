import $$ from "dom7"
import Template7 from "template7"
import Framework7 from "framework7/framework7.esm.bundle.js"
import io from "socket.io-client"
import localforage from "localforage"

// Import F7 Styles
import "framework7/css/framework7.bundle.css"

// Import Icons and App Custom Styles
import "../css/icons.css"
import "../css/app.css"
// Import Cordova APIs
import cordovaApp from "./cordova-app.js"
// Import Routes
import routes from "./routes.js"

var socket = io("https://ims.globalmartindia.com/", {
  // transports: ['websocket']
  // , upgrade: false
  path: "/sph/socket.io",
  secure: true,
  rejectUnauthorized: false,
})

var ifs_token = "null"
var userName = ""
var ifs_token_expiry = 0
var validracks = []
var allracks = []
var skipracks = []
var physicalracks = []
var damagedracks = []
var discardedracks = []

console.log(
  'localStorage.getItem("ifs_token") :>> ',
  localStorage.getItem("ifs_token")
)

if (
  localStorage.getItem("ifs_token") == "[object Object]" ||
  localStorage.getItem("ifs_token") == null
) {
} else {
  let ifs_token_obj = JSON.parse(localStorage.getItem("ifs_token"))
  if (typeof ifs_token_obj === "object" && ifs_token_obj !== null) {
    // console.log(ifs_token_obj);
    if ("token" in ifs_token_obj) {
      ifs_token = ifs_token_obj.token
      userName = ifs_token_obj.username
      ifs_token_expiry = ifs_token_obj.expiry
      validracks = ifs_token_obj.validracks
      allracks = ifs_token_obj.allracks
      skipracks = ifs_token_obj.skipracks
      physicalracks = ifs_token_obj.physicalracks
      damagedracks = ifs_token_obj.damagedracks
      discardedracks = ifs_token_obj.discardedracks
    }
  }
}

// prettier-ignore
let adjustmentRacks = [
  'X', 'Y', 'Z'
]

let isCordova = 0

let viewOptions = {}
if (!Framework7.device.cordova) {
  viewOptions = {
    pushState: true,
    pushStateRoot: document.location.pathname.split("index.html")[0],
    cache: false,
    xhrCache: false,
  }
} else {
  isCordova = 1
}

// Import main app component
import App from "../app.f7.html"

var app = new Framework7({
  root: "#app", // App root element
  component: App, // App main component
  id: "io.framework7.iosplims", // App bundle ID
  name: "IOSPL-IMS", // App name
  theme: "auto", // Automatic theme detection
  version: "2.1.6",
  touch: {
    tapHold: true,
  },
  // App routes
  routes: routes,
  view: viewOptions,
  data: function () {
    return {
      user: {
        firstName: "John",
        lastName: "Doe",
      },
      isCordova: isCordova,
      apiUrl: "https://oms3.infiniteshopping.com/admin/api/remote.php",
      ifs_token: ifs_token,
      ifs_token_expiry: ifs_token_expiry,
      userName: userName,
      isAdmin: userName === "admin" ? 1 : 0,
      isBarcodeScannerOn: false,
      socket: socket,
      localforage: localforage,
      defaultTimeout: 15000,
      validRacks: validracks,
      allRacks: allracks,
      skipRacks: skipracks,
      physicalRacks: physicalracks,
      damagedRacks: damagedracks,
      discardedRacks: discardedracks,
      adjustmentRacks: skipracks,
    }
  },

  // Register service worker
  serviceWorker: Framework7.device.cordova
    ? {}
    : {
        path: "/service-worker.js",
      },
  // Input settings
  input: {
    scrollIntoViewOnFocus:
      Framework7.device.cordova && !Framework7.device.electron,
    scrollIntoViewCentered:
      Framework7.device.cordova && !Framework7.device.electron,
  },
  // Cordova Statusbar settings
  statusbar: {
    iosOverlaysWebView: true,
    androidOverlaysWebView: false,
  },
  on: {
    init: function () {
      var f7 = this
      if (f7.device.cordova) {
        // Init cordova APIs (see cordova-app.js)
        cordovaApp.init(f7)
      }
    },
  },
})

Template7.registerHelper("incremented", function (index, start = 0) {
  index = index + start + 1
  return index
})
Template7.registerHelper("add_subtitle", function (value, label) {
  if (value) {
    return `<div class="item-subtitle">
              ${label} ${value}
            </div>`
  } else {
    return ""
  }
})
Template7.registerHelper("if_compare", function (a, operator, b, options) {
  var match = false
  if (
    (operator === "==" && a == b) ||
    (operator === "===" && a === b) ||
    (operator === "!=" && a != b) ||
    (operator === ">" && a > b) ||
    (operator === "<" && a < b) ||
    (operator === ">=" && a >= b) ||
    (operator === "<=" && a <= b)
  ) {
    match = true
  }
  if (match) return options.fn(this)
  else return options.inverse(this)
})

Template7.registerHelper("json_stringify", function (context) {
  // context =  context.replace(/[^a-zA-Z0-9 ]/g, '');
  return JSON.stringify(context)
})

function submitLoginForm() {
  var username = $$('#my-login-screen [name="username"]').val()
  var password = $$('#my-login-screen [name="password"]').val()

  app.request
    .promise({
      url: "https://oms3.infiniteshopping.com/admin/api/login.php",
      data: {
        username: username,
        password: password,
      },
      method: "POST",
      dataType: "json",
    })
    .then(function ({ data, xhr, status }) {
      console.log(data)
      app.preloader.hide()
      // console.log('Framework7.device.cordova :', Framework7.device.cordova)
      // console.log('data :', data)
      if (data.login == 1) {
        if (data.ver !== app.version && Framework7.device.cordova) {
          app.dialog.alert(
            "You have an older version of the app. please download the newer version of the app",
            function () {
              let href = `http://192.168.0.130/uploads/ifsapp${data.ver}.apk`

              setTimeout(function () {
                cordova.InAppBrowser.open(href, "_system")
                // window.open(href, '_system')
              }, 500)
            }
          )
          return
        }
        // localStorage.setItem("ifs_token", JSON.stringify(data))
        app.data.ifs_token = data.token
        app.data.userName = data.username
        app.data.ifs_token_expiry = data.expiry
        app.data.validRacks = data.validracks
        app.data.allRacks = data.allracks
        app.data.skipRacks = data.skipracks
        app.data.physicalRacks = data.physicalracks
        app.data.damagedRacks = data.damagedracks
        app.data.discardedRacks = data.discardedracks
        app.loginScreen.close("#my-login-screen")
        // app.dialog.alert('Login Successful...');
        console.log("app :>> ", app)
        console.log("this :>> ", this)
      } else {
        app.dialog.alert("Login failed... Try again(1)")
      }
    })
    .catch((error) => {
      app.dialog.alert("Login failed... Try again(2)")
      // console.log(error);
    })
}
$$("input").on("keydown", function (e) {
  if (e.keyCode === 13) {
    submitLoginForm()
  }
})
$$("#my-login-screen .login-button").on("click", function () {
  submitLoginForm()
})

$$(".logout-button").on("click", function () {
  localStorage.setItem("ifs_token", null)
  app.data.ifs_token = "null"
  app.data.ifs_token_expiry = 0
  app.data.validracks = []
  app.data.allracks = []
  app.data.skipracks = []
  app.data.physicalracks = []
  app.data.damagedracks = []
  app.data.discardedracks = []
  app.dialog.alert("Logout Successful...")
  app.loginScreen.open("#my-login-screen")
})

setInterval(() => {
  console.log("calling refresh 1...")
  if (app.data.ifs_token != "null") {
    app.request
      .promise({
        url: "https://oms3.infiniteshopping.com/admin/api/refreshtoken.php",
        data: {
          refresh: "1",
        },
        method: "POST",
        dataType: "json",
        headers: {
          Authorization: app.data.ifs_token,
        },
      })
      .then(function ({ data, xhr, status }) {
        // console.log('calling refresh successful');
        if (data.login == 1) {
          localStorage.setItem("ifs_token", JSON.stringify(data))
          app.data.ifs_token = data.token
          app.data.userName = data.username
          app.data.ifs_token_expiry = data.expiry
          app.data.validracks = data.validracks
          app.data.allracks = data.allracks
          app.data.skipracks = data.skipracks
          app.data.physicalracks = data.physicalracks
          app.data.damagedracks = data.damagedracks
          app.data.discardedracks = data.discardedracks
        } else {
          localStorage.setItem("ifs_token", null)
          app.data.ifs_token = "null"
        }
      })
      .catch((error) => {
        // localStorage.setItem('ifs_token', null)
        // app.data.ifs_token = 'null'
      })
  } else {
    // console.log('calling refresh cancelled');
  }
}, 300000)

document.addEventListener("deviceready", onDeviceReady, false)

function onDeviceReady() {
  console.log("onDeviceReady1")
  document.addEventListener(
    "backbutton",
    function navigateBack() {
      // Use Framework7's router to navigate back
      var mainView = app.views.main

      var leftp = app.panel.left && app.panel.left.opened
      var rightp = app.panel.right && app.panel.right.opened

      if (app.data.isBarcodeScannerOn === true) {
        app.data.isBarcodeScannerOn = false
        return false
      }

      if (leftp || rightp) {
        app.panel.close()
        return false
      } else if ($$(".calendar-modal.modal-in").length > 0) {
        app.calendar.close()
        return false
      } else if ($$(".popup.modal-in").length > 0) {
        app.dialog.close()
        app.popup.close()
        return false
      } else if (app.views.main.router.url == "/") {
        navigator.app.exitApp()
      } else {
        mainView.router.back()
      }
    },
    false
  )
}

socket.on("lock_instapick", function (data) {
  // console.log('lock_instapick',data);
  app.emit("msg_lock_instapick", data)
})

socket.on("update_instapick", function (data) {
  // console.log('update_instapick',data);
  app.emit("msg_update_instapick", data)
})

socket.on("complete_instapick", function (data) {
  // console.log('complete_instapick',data);
  app.emit("msg_complete_instapick", data)
})

socket.on("unlock_instapick", function (data) {
  // console.log('unlock_instapick',data);
  app.emit("msg_unlock_instapick", data)
})

socket.on("putback_instapick", function (data) {
  // console.log('putback_instapick',data);
  app.emit("msg_putback_instapick", data)
})

socket.on("lock_allotshift", function (data) {
  app.emit("msg_lock_allotshift", data)
})

socket.on("complete_allotshift", function (data) {
  app.emit("msg_complete_allotshift", data)
})

socket.on("unlock_allotshift", function (data) {
  app.emit("msg_unlock_allotshift", data)
})

socket.on("disconnect", () => {
  // console.log('you have been disconnected');
})

socket.on("reconnect", () => {
  // console.log('you have been reconnected');
})

socket.on("reconnect_error", () => {
  // console.log('attempt to reconnect has failed');
})

socket.on("connect", () => {
  // console.log('you have been connected');
})
