
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  // Lifecycle Hooks
  beforeCreate() {
    console.log("componentBeforeCreate", this);
  },

  created() {
    console.log("componentCreated", this);
  },

  beforeMount() {
    console.log("componentBeforeMount", this);
  },

  mounted() {
    const self = this;
    const app = self.$app;
    const $ = self.$$;
    const router = self.$router;
    self.calendarrange = app.calendar.create({
      inputEl: '#photos-calendar-range',
      dateFormat: 'dd/mm/yyyy',
      rangePicker: true,
      value: [new Date(), new Date()]
    });
    $("input").on("keydown", function (e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        self.FindShipmentPhotos();
      }
    });
    $(document).on('click', ".photo-browser-share", function () {
      // this is the complete list of currently supported params you can pass to the plugin (all optional)
      // var options = {
      //   message: 'Shipment Images \n' + self.images[self.shipmentPhotoBrowser.activeIndex].url, // not supported on some apps (Facebook, Instagram)
      //   subject: 'Shipment Image ' + self.images[self.shipmentPhotoBrowser.activeIndex].caption, // fi. for email
      //   files: [encodeURI(self.images[self.shipmentPhotoBrowser.activeIndex].url)], // an array of filenames either locally or remotely
      //   chooserTitle: 'Pick an app', // Android only, you can override the default share sheet title
      // };
      var onSuccess = function (result) {
        console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true

        console.log("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
      };

      var onError = function (msg) {
        console.log("Sharing failed with message: " + msg);
      };

      window.plugins.socialsharing.shareViaEmail('Shipment Image \n\n' + self.images[self.shipmentPhotoBrowser.activeIndex].url, // can contain HTML tags, but support on Android is rather limited:  http://stackoverflow.com/questions/15136480/how-to-send-html-content-with-image-through-android-default-email-client
      'Shipment Image', null, // TO: must be null or an array
      null, // CC: must be null or an array
      null, // BCC: must be null or an array
      self.images[self.shipmentPhotoBrowser.activeIndex].url, // FILES: can be null, a string, or an array
      onSuccess, // called when sharing worked, but also when the user cancelled sharing via email. On iOS, the callbacks' boolean result parameter is true when sharing worked, false if cancelled. On Android, this parameter is always true so it can't be used). See section "Notes about the successCallback" below.
      onError // called when sh*t hits the fan
      ); // window.plugins.socialsharing.shareWithOptions(options, onSuccess, onError);
    });
  },

  beforeDestroy() {
    console.log("componentBeforeDestroy", this);
  },

  destroyed() {
    console.log("componentDestroyed", this);
  },

  // Component Data
  data: function () {
    // Must return an object
    return {
      images: [],
      imagesdata: {},
      calendarrange: "",
      shipmentPhotoBrowser: null,
      checkboxesEnabled: false
    };
  },
  // Component Methods
  methods: {
    ShareImages: function () {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$router;
      var checkedBoxes = document.querySelectorAll('input[name=img-checkbox]:checked'); // for (const checkedBoxes of chkbox) {
      //   console.log('chkbox: ', chkbox);
      // }

      let imageUrls = [...checkedBoxes].map(chkbox => {
        // console.log("chkbox.getAttribute('data-img-id') :", chkbox.getAttribute('data-img-id'));
        return self.images[parseInt(chkbox.getAttribute('data-img-id'))].url;
      });
      window.plugins.socialsharing.shareViaEmail('Shipment Images \n\n' + imageUrls.join('\n'), // can contain HTML tags, but support on Android is rather limited:  http://stackoverflow.com/questions/15136480/how-to-send-html-content-with-image-through-android-default-email-client
      'Shipment Images', null, // TO: must be null or an array
      null, // CC: must be null or an array
      null, // BCC: must be null or an array
      imageUrls, // FILES: can be null, a string, or an array
      onSuccess, // called when sharing worked, but also when the user cancelled sharing via email. On iOS, the callbacks' boolean result parameter is true when sharing worked, false if cancelled. On Android, this parameter is always true so it can't be used). See section "Notes about the successCallback" below.
      onError // called when sh*t hits the fan
      ); // var options = {
      //   message: 'Shipment Images \n\n' + imageUrls.join('\n'), // not supported on some apps (Facebook, Instagram)
      //   subject: 'Shipment Images ', // fi. for email
      //   // files: imageUrls, // an array of filenames either locally or remotely
      //   chooserTitle: 'Pick an app', // Android only, you can override the default share sheet title
      // };

      var onSuccess = function (result) {
        console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true

        console.log("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
      };

      var onError = function (msg) {
        console.log("Sharing failed with message: " + msg);
      }; // window.plugins.socialsharing.shareWithOptions(options, onSuccess, onError);
      // window.plugins.socialsharing.share('Shipment Images', "Shipment Images", 'http://192.168.0.130', imageUrls);

    },
    ToggleCheckboxForShare: function () {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$router;
      console.log('self.checkboxesEnabled0 :', self.checkboxesEnabled);

      if (self.checkboxesEnabled == false) {
        $('.img-checkbox').removeAttr("disabled");
        self.$setState({
          checkboxesEnabled: true
        });
        self.$tick(() => {
          console.log('self.checkboxesEnabled1 :', self.checkboxesEnabled);
          $(".share-images").show();
        });
      } else {
        $('.img-checkbox').prop("disabled", true);
        $('.img-checkbox').prop("checked", false);
        self.$setState({
          checkboxesEnabled: false
        });
        self.$tick(() => {
          console.log('self.checkboxesEnabled2 :', self.checkboxesEnabled);
          $(".share-images").hide();
        });
      }
    },
    FindShipmentPhotos: function (scannedSearchTerm = null) {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$router;

      if (!$('#searchterm').val() && scannedSearchTerm === null) {
        app.dialog.alert('Please enter a valid search term..');
        return;
      }

      let searchterm;
      let calendarrange;

      if (scannedSearchTerm !== null) {
        searchterm = scannedSearchTerm;
      } else {
        let formData = app.form.convertToData('#find-photos-form');
        searchterm = formData.searchterm;
        calendarrange = formData.photoscalendarrange;
      }

      if (scannedSearchTerm !== null) {
        calendarrange = "";
      }

      let postdata = {
        searchterm,
        calendarrange
      };
      app.dialog.preloader(`Fetching Data`);
      app.request.promise({
        url: 'http://192.168.0.130/getphotos.php',
        data: postdata,
        method: "POST",
        dataType: "json",
        timeout: app.data.defaultTimeout
      }).then(function ({
        data,
        xhr,
        status
      }) {
        app.dialog.close();

        if (data.result == 1) {
          self.$setState({
            images: data.images,
            imagesdata: data.imagesdata
          });
          self.$tick(() => {
            let popup = app.popup.create({
              content: $(".popup-show-images"),
              on: {
                opened: function () {},
                closed: function () {
                  self.$setState({
                    images: [],
                    imagesdata: {}
                  });
                  $('#searchterm').val('');
                  self.calendarrange.setValue([new Date(), new Date()]);
                }
              }
            });
            popup.open(true);
          });
        } else {
          console.log('object :', ' ');
          app.dialog.alert(`Error: ${data.error}<br> Please check case in search term`, "Error");
        }
      }).catch(function (error) {
        app.dialog.close();
        app.dialog.alert(`Error while fetching data. <br> Error 2: ${error.message}`, "Error");
      });
    },
    ShowLargeImage: function (id) {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$router;

      if (self.checkboxesEnabled == true) {
        return;
      }

      app.photoBrowser.destroy();
      self.shipmentPhotoBrowser = app.photoBrowser.create({
        photos: self.images,
        type: "popup",
        renderToolbar: function () {
          const pb = this;
          let iconsColor = pb.params.iconsColor;
          if (!pb.params.iconsColor && pb.params.theme === 'dark') iconsColor = 'white';
          const toolbarHtml = `
            <div class="toolbar toolbar-bottom tabbar">
              <div class="toolbar-inner">
                <a class="link photo-browser-prev">
                  <i class="icon icon-back ${iconsColor ? `color-${iconsColor}` : ''}"></i>
                </a>
                <a class="link photo-browser-share external" target="_system" data-attr-url="${pb.$el}">
                  <i class="icon f7-icons ${iconsColor ? `color-${iconsColor}` : ''}">square_arrow_up</i>
                </a>
                <a class="link photo-browser-next">
                  <i class="icon icon-forward ${iconsColor ? `color-${iconsColor}` : ''}"></i>
                </a>
              </div>
            </div>
          `.trim();
          return toolbarHtml;
        }
      });
      self.shipmentPhotoBrowser.open(id);
    },
    ShowScanner: function () {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$router;
      app.data.isBarcodeScannerOn = true;
      cordova.plugins.barcodeScanner.scan(function (result) {
        if (result.cancelled !== true) {
          app.data.isBarcodeScannerOn = false;
        }

        let scan_result = result.text.toString().trim();
        let search_by;
        let search_val;

        if (result.format.includes("DATA_MATRIX")) {
          search_val = scan_result.split("-")[1].split("_")[0];
          search_by = "shipment_id";
        } else if (result.format.includes("CODE_128")) {
          search_val = scan_result;
          search_by = "tracking_no";
        } else if (result.format.includes("CODE_39")) {
          search_val = scan_result;
          search_by = "tracking_no";
        } else {
          app.dialog.alert("Invalid Scan...", "Error");
          return;
        }

        self.FindShipmentPhotos(search_val);
      }, function (error) {
        // app.data.isBarcodeScannerOn = false;
        app.dialog.alert("Scanning failed: " + error);
      }, {
        preferFrontCamera: false,
        // iOS and Android
        showFlipCameraButton: false,
        // iOS and Android
        showTorchButton: true,
        // iOS and Android
        torchOn: false,
        // Android, launch with the torch switched on (if available)
        saveHistory: false,
        // Android, save scan history (default false)
        prompt: "Place a barcode inside the scan area",
        // Android
        resultDisplayDuration: 500,
        // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
        formats: "QR_CODE,CODE_128,UPC_A,UPC_E,EAN_8,EAN_13,CODE_39,DATA_MATRIX",
        // default: all but PDF_417 and
        orientation: "portrait",
        // Android only (portrait|landscape), default unset so it rotates with the device
        disableAnimations: true,
        // iOS
        disableSuccessBeep: true,
        // iOS and Android
        continuousMode: false // Android

      });
    }
  },
  // Page Events
  on: {
    pageMounted: function (e, page) {
      console.log("pageMounted", page);
    },
    pageInit: function (e, page) {
      console.log("pageInit", page);
    },
    pageBeforeIn: function (e, page) {
      console.log("pageBeforeIn", page);
    },
    pageAfterIn: function (e, page) {
      console.log("pageAfterIn", page);
    },
    pageBeforeOut: function (e, page) {
      console.log("pageBeforeOut", page);
    },
    pageAfterOut: function (e, page) {
      console.log("pageAfterOut", page);
    },
    pageBeforeRemove: function (e, page) {
      console.log("pageBeforeRemove", page);
    }
  },
  id: 'fc36836019',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=view-shipment-photos><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Back</span></a></div><div class=title>View Shipment Photos</div><div class=right><a @click=FindShipmentPhotos(null) href=# class=link>Find</a></div></div></div><div class="fab fab-extended fab-right-bottom color-red"><a @click=ShowScanner><i class="icon f7-icons size-32">qrcode</i></a></div><div class="page-content login-screen-content"><div class=login-screen-title>Enter OrderId / ShipmentId / SKU</div><form class=list id=find-photos-form><ul><li class="item-content item-input"><div class=item-inner><div class="item-title item-floating-label">Search Term</div><div class=item-input-wrap><input type=text name=searchterm id=searchterm> <span class=input-clear-button></span></div></div></li><li class="item-content item-input"><div class=item-inner><div class="item-title item-floating-label">Date Range</div><div class=item-input-wrap><input type=text name=photoscalendarrange placeholder="Select date range" readonly=readonly id=photos-calendar-range></div></div></li></ul></form></div><div class="popup popup-show-images"><div class=page><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=left><a href=# class="link popup-close"><i class="icon icon-back"></i> <span class=ios-only>Back</span></a></div><div class=title>Shipment Images</div><div class=right><a href=# class="link popup-close">Close</a></div></div></div><div class="share-images fab fab-extended fab-right-bottom" style=display:none;><a class="fab color-purple" @click=ShareImages><i class="icon f7-icons size-32">square_arrow_up</i></a></div><div class=page-content><div class="list media-list"></div>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.imagesdata, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=block><div class=date>Date: ';
          r += c(data_2 && data_2.key, ctx_2);
          r += '</div><div class=thumbImages>';
          r += Template7Helpers.each.call(ctx_2, ctx_2, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div style="display: inline-block;position: relative;"><label class="item-checkbox item-content" style=""><input class=img-checkbox data-img-id="';
              r += c(ctx_3.id, ctx_3);
              r += '" name=img-checkbox type=checkbox disabled=disabled> <i class="icon icon-checkbox" style="position: absolute;"></i> <img @taphold.native=ToggleCheckboxForShare @click="ShowLargeImage(';
              r += c(ctx_3.id, ctx_3);
              r += ')" style=width:100px; src=';
              r += c(ctx_3.url, ctx_3);
              r += ' alt=""><!-- <img @click="ToggleCheckboxForShare"  style="width:100px;" src="';
              r += c(ctx_3.url, ctx_3);
              r += '" alt="" /> --></label></div>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    