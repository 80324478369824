
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      Template7.registerPartial('show-barcode', '<div class="popup popup-show-barcodes">    <div class="page">      <div class="navbar">        <div class="navbar-bg"></div>        <div class="navbar-inner">          <div class="left">            <a href="#" class="link popup-close">              <i class="icon icon-back"></i>              <span class="ios-only">Back</span>            </a>          </div>          <div class="title">SKU: {{ barcodeSKU }}</div>          <div class="right">            <a href="#" class="link popup-close">Close</a>          </div>        </div>      </div>      <div class="page-content">        <div class="order-rows list accordion-list">          <ul>            {{#each popup3BarCodes}}            <li class="barcode-item" style="text-align: center;">              <svg id="barcode_lsi_{{ lsi }}"></svg>            </li>            {{/each}}          </ul>        </div>      </div>    </div>  </div>');
  
      export default {
  // Lifecycle Hooks
  beforeCreate() {
    console.log("componentBeforeCreate", this);
  },

  created() {
    console.log("componentCreated", this);
  },

  beforeMount() {
    console.log("componentBeforeMount", this);
  },

  mounted() {
    console.log("componentMounted", this);
  },

  beforeDestroy() {
    console.log("componentBeforeDestroy", this);
  },

  destroyed() {
    console.log("componentDestroyed", this);
  },

  // Component Data
  data: function () {
    // Must return an object
    return {
      name: "Jimmy",
      age: 25,
      like: ["Tennis", "Chess", "Football"],
      popup3BarCodes: [],
      barcodeSKU: ""
    };
  },
  // Component Methods
  methods: {
    openAlert: function () {
      var self = this;
      self.$app.dialog.alert("Hello World");
    },
    ShowScanner: function () {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$router;
      app.data.isBarcodeScannerOn = true;
      cordova.plugins.barcodeScanner.scan(function (result) {
        if (result.cancelled !== true) {
          app.data.isBarcodeScannerOn = false;
        }

        let resultArray = result.text.split("-");
        let postdata;

        if (result.format.includes("UPC")) {
          postdata = {
            w: "GetLsiJson",
            upc: result.text.trim()
          };
        } else if (result.format.includes("QR_CODE")) {
          let resultArray = result.text.split("-");
          postdata = {
            w: "GetLsiJson",
            product_id: resultArray[0]
          };
        } else {
          postdata = {
            w: "GetLsiJson",
            lsi: result.text.trim()
          };
        }

        app.dialog.preloader("Finding Barcodes");
        app.request.promise({
          url: app.data.apiUrl,
          data: postdata,
          method: "POST",
          dataType: "json",
          headers: {
            Authorization: app.data.ifs_token
          },
          timeout: app.data.defaultTimeout
        }).then(function ({
          data,
          xhr,
          status
        }) {
          app.dialog.close();

          if (data.result == 1) {
            let lsi_json = JSON.parse(data.lsi_json); // console.log(lsi_json);

            let popup3BarCodes = lsi_json.map(item => {
              return {
                lsi: item.listing_sku_id
              };
            });
            self.$setState({
              popup3BarCodes: popup3BarCodes,
              barcodeSKU: lsi_json[0].sku
            });
            self.$tick(() => {
              lsi_json.map(item => {
                let width = 2;

                if (item.fnsku.toString().length > 12) {
                  width = 1;
                }

                JsBarcode(`#barcode_lsi_${item.listing_sku_id}`, item.fnsku, {
                  width: width
                });
              });
              let popup3 = app.popup.create({
                content: $(".popup-show-barcodes"),
                on: {
                  opened: function () {},
                  closed: function () {
                    self.$setState({
                      popup3BarCodes: []
                    });
                  }
                }
              });
              popup3.open(true);
            });
          }
        }).catch(function (error) {
          app.dialog.close();
          app.dialog.alert("AJAX Error: " + error.message);
        }); // app.dialog.alert("We got a barcode\n" +
        //   "Result: " + result.text + "\n" +
        //   "Format: " + result.format + "\n" +
        //   "Cancelled: " + result.cancelled);
      }, function (error) {
        // app.data.isBarcodeScannerOn = false;
        app.dialog.alert("Scanning failed: " + error);
      }, {
        preferFrontCamera: false,
        // iOS and Android
        showFlipCameraButton: false,
        // iOS and Android
        showTorchButton: true,
        // iOS and Android
        torchOn: false,
        // Android, launch with the torch switched on (if available)
        saveHistory: false,
        // Android, save scan history (default false)
        prompt: "Place a barcode inside the scan area",
        // Android
        resultDisplayDuration: 500,
        // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
        formats: "QR_CODE,CODE_128,UPC_A,UPC_E,EAN_8,EAN_13,CODE_39",
        // default: all but PDF_417 and
        orientation: "portrait",
        // Android only (portrait|landscape), default unset so it rotates with the device
        disableAnimations: true,
        // iOS
        disableSuccessBeep: true // iOS and Android

      });
    }
  },
  // Page Events
  on: {
    pageMounted: function (e, page) {
      console.log("pageMounted", page);
    },
    pageInit: function (e, page) {
      console.log("pageInit", page);
    },
    pageBeforeIn: function (e, page) {
      console.log("pageBeforeIn", page);
    },
    pageAfterIn: function (e, page) {
      console.log("pageAfterIn", page);
    },
    pageBeforeOut: function (e, page) {
      console.log("pageBeforeOut", page);
    },
    pageAfterOut: function (e, page) {
      console.log("pageAfterOut", page);
    },
    pageBeforeRemove: function (e, page) {
      console.log("pageBeforeRemove", page);
    }
  },
  id: 'b528984792',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=about><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Back</span></a></div><div class=title>About</div></div></div><div class="fab fab-extended fab-right-bottom color-red"><a @click=ShowScanner><i class="icon f7-icons size-32">qrcode</i></a></div><div class=page-content>';
      r += Template7Helpers._partial.call(ctx_1, "show-barcode", {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    