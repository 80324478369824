export default {
  // Lifecycle Hooks
  beforeCreate() {
    console.log("componentBeforeCreate", this);
  },

  created() {
    console.log("componentCreated", this);
  },

  beforeMount() {
    console.log("componentBeforeMount", this);
  },

  mounted() {
    const self = this;
    const app = self.$app;
    const $ = self.$$;
    const router = self.$router;
    $(document).on("keyup", "#rackid", function (e) {
      if ($(this).val().length >= 1) {
        $(this).blur().attr("type", "tel").focus();
      } else {
        $(this).blur().attr("type", "text").focus();
      }
    });
    $("input").on("keydown", function (e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        self.GotoSelectedRack();
      }
    });
  },

  beforeDestroy() {
    console.log("componentBeforeDestroy", this);
  },

  destroyed() {
    console.log("componentDestroyed", this);
  },

  // Component Data
  data: function () {
    // Must return an object
    return {
      name: "Jimmy",
      age: 25,
      like: ["Tennis", "Chess", "Football"],
      selectedRackId: ""
    };
  },
  // Component Methods
  methods: {
    GotoRack: function (rackid) {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$router;
      let validRacks = app.data.validRacks;

      if (app.data.userName === "admin") {
        validRacks = app.data.allRacks;
      }

      console.log('validRacks :>> ', validRacks);

      if (validRacks.includes(rackid)) {
        router.navigate(`/rackcheck/${rackid}`, {
          ignoreCache: true
        });
      } else {
        app.dialog.alert(`Invalid Rack... Try Scanning Again...`, "Error");
      }
    },
    GotoSelectedRack: function () {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$router;
      let formData = app.form.convertToData('#rack-select-form');
      let rackid = formData.rackid;
      console.log('app.data :>> ', app.data);
      let validRacks = app.data.validRacks;

      if (app.data.userName === "admin") {
        validRacks = app.data.allRacks;
      }

      if (validRacks.includes(rackid) && rackid !== "") {
        router.navigate(`/rackcheck/${rackid}`, {
          ignoreCache: true
        });
      } else {
        app.dialog.alert(`Invalid Rack...`, "Error");
      }
    },
    ShowScanner: function () {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$router;
      app.data.isBarcodeScannerOn = true;
      cordova.plugins.barcodeScanner.scan(function (result) {
        if (result.cancelled !== true) {
          app.data.isBarcodeScannerOn = false;
        }

        self.GotoRack(result.text.trim()); // app.dialog.alert("We got a barcode\n" +
        //   "Result: " + result.text + "\n" +
        //   "Format: " + result.format + "\n" +
        //   "Cancelled: " + result.cancelled);
      }, function (error) {
        // app.data.isBarcodeScannerOn = false;
        app.dialog.alert("Scanning failed: " + error);
      }, {
        preferFrontCamera: false,
        // iOS and Android
        showFlipCameraButton: false,
        // iOS and Android
        showTorchButton: true,
        // iOS and Android
        torchOn: false,
        // Android, launch with the torch switched on (if available)
        saveHistory: false,
        // Android, save scan history (default false)
        prompt: "Place a barcode inside the scan area",
        // Android
        resultDisplayDuration: 500,
        // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
        formats: "QR_CODE",
        // default: all but PDF_417 andz
        orientation: "portrait",
        // Android only (portrait|landscape), default unset so it rotates with the device
        disableAnimations: true,
        // iOS
        disableSuccessBeep: true,
        // iOS and Android
        continuousMode: false // Android

      });
    }
  },
  // Page Events
  on: {
    pageMounted: function (e, page) {
      console.log("pageMounted", page);
    },
    pageInit: function (e, page) {
      console.log("pageInit", page);
    },
    pageBeforeIn: function (e, page) {
      console.log("pageBeforeIn", page);
    },
    pageAfterIn: function (e, page) {
      console.log("pageAfterIn", page);
    },
    pageBeforeOut: function (e, page) {
      console.log("pageBeforeOut", page);
    },
    pageAfterOut: function (e, page) {
      console.log("pageAfterOut", page);
    },
    pageBeforeRemove: function (e, page) {
      console.log("pageBeforeRemove", page);
    }
  },
  id: 'f03748ee99',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=about><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Back</span></a></div><div class=title>Select Rack</div><div class=right><a @click=GotoSelectedRack href=# class=link>Find</a></div></div></div><div class="fab fab-extended fab-right-bottom color-red"><a @click=ShowScanner><i class="icon f7-icons size-32">qrcode</i></a></div><div class="page-content login-screen-content"><div class=login-screen-title>Enter Rack Id</div><form class=list id=rack-select-form><ul><li class="item-content item-input"><div class=item-inner><div class="item-title item-floating-label">Rack Id ( A11, B61, etc )</div><div class=item-input-wrap><input type=text name=rackid id=rackid style="font-size:100px; height:auto;"> <span class=input-clear-button></span></div></div></li></ul></form></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};