export default {
  // Lifecycle Hooks
  beforeCreate() {
    console.log("componentBeforeCreate", this);
  },

  created() {
    console.log("componentCreated", this);
  },

  beforeMount() {
    console.log("componentBeforeMount", this);
  },

  mounted() {
    console.log("componentMounted", this);
    const self = this;
    const app = self.$app;
    const $ = self.$$;
    const router = self.$router; // const localforage = app.data.localforage

    let shipmentVirtualList = app.virtualList.create({
      // List Element
      el: ".shipment-list",
      // Pass array with items
      items: self.tracking_nos_info,
      // Custom search function for searchbar
      searchAll: function (query, items) {
        var found = [];

        for (var i = 0; i < items.length; i++) {
          if (items[i].shipment_id.toLowerCase().indexOf(query.toLowerCase()) >= 0 || items[i].tracking_no.toLowerCase().indexOf(query.toLowerCase()) >= 0 || query.trim() === "") found.push(i);
        }

        return found; //return array with mathced indexes
      },
      itemTemplate: `<li class="tracking-no-item swipeout no-margin-left no-margin-right tracking-no-{{id}}">
            <div class="item-content swipeout-content">
                <div class="item-inner">
                  <div class="item-title">{{this.shipment_id}} - {{this.tracking_no}}</div>
                </div>
            </div>
            <div class="swipeout-actions-right">
              <a href="#" data-attr-shipment-id='{{this.shipment_id}}' data-attr-tracking-no='{{this.tracking_no}}' class="delete-tracking-no color-red">Delete</a>
            </div>
          </li>`,
      // Item height
      height: app.theme === "ios" ? 48 : app.theme === "md" ? 48 : 48
    });
    $(document).off("click", ".delete-tracking-no").on("click", ".delete-tracking-no", function (e) {
      let shipment_id = $(this).attr("data-attr-shipment-id");
      let postdata = {
        w: "DeleteScannedShipmentAWBNosV2",
        shipment_ids: [shipment_id]
      };
      app.dialog.confirm(`Delete tracking No: ${shipment_id}?`, function () {
        app.request.promise({
          url: app.data.apiUrl,
          data: postdata,
          method: "POST",
          dataType: "json",
          headers: {
            Authorization: app.data.ifs_token
          },
          timeout: app.data.defaultTimeout
        }).then(({
          data,
          xhr,
          status
        }) => {
          app.dialog.close();

          if (data.result == 1) {
            let tracking_nos_info = self.tracking_nos_info;
            let index = tracking_nos_info.findIndex(i => i.shipment_id === shipment_id);

            if (index !== -1) {
              tracking_nos_info.splice(index, 1);
            }

            self.$setState({
              tracking_nos_info: tracking_nos_info,
              shipmentCount: tracking_nos_info.length,
              db_shipmentCount: tracking_nos_info.length
            }); // let vindex = shipmentVirtualList.items.findIndex((list_tracking_no) => {
            //   return (list_tracking_no === tracking_no)
            // });
            // shipmentVirtualList.deleteItem(vindex)

            shipmentVirtualList.replaceAllItems(tracking_nos_info);
          } else {
            app.dialog.alert(`Error while saving data. <br> Error: ${data.error} `);
          }
        }).catch(error => {
          app.preloader.hide();

          if (error == 401) {
            app.loginScreen.open("#my-login-screen");
          } else {
            app.dialog.alert("AJAX Error(4): " + error.message);
          }
        });
      });
    });
    app.dialog.preloader("Fetching data...");
    let ship_date = document.querySelector("#ship_date").value;
    ship_date = new Date().getTime() / 1000;
    ship_date = ship_date - ship_date % 86400;
    app.request.promise({
      url: app.data.apiUrl,
      data: {
        w: "GetScannedShipmentAWBNosV2",
        ship_date: ship_date
      },
      method: "POST",
      dataType: "json",
      headers: {
        Authorization: app.data.ifs_token
      }
    }).then(function ({
      data,
      xhr,
      status
    }) {
      app.dialog.close();
      self.$setState({
        tracking_nos_info: data.tracking_nos_info,
        shipmentCount: data.tracking_nos_info.length,
        db_shipmentCount: data.tracking_nos_info.length
      });
      self.$tick(() => {
        shipmentVirtualList.replaceAllItems(data.tracking_nos_info);
      });
    }).catch(function (error) {
      app.dialog.alert(`Error while fetching data. <br> Error: ${error.message}`, "Error");
    });
  },

  beforeDestroy() {
    console.log("componentBeforeDestroy", this);
  },

  destroyed() {
    console.log("componentDestroyed", this);
  },

  // Component Data
  data: function () {
    // Must return an object
    return {
      tracking_nos_info: {},
      shipmentCount: 0,
      shipDate: new Date().toISOString().substring(0, 10),
      db_shipmentCount: 0
    };
  },
  // Component Methods
  methods: {
    openAlert: function () {
      var self = this;
      self.$app.dialog.alert("Hello World");
    },
    ChangeShipDate: function () {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$router;
      let shipmentVirtualList = app.virtualList.get(".shipment-list");
      app.dialog.preloader("Fetching data...");
      let ship_date = document.querySelector("#ship_date").value;
      ship_date = new Date(`${ship_date}`).getTime() / 1000;
      ship_date = ship_date - ship_date % 86400;
      app.request.promise({
        url: app.data.apiUrl,
        data: {
          w: "GetScannedShipmentAWBNosV2",
          ship_date: ship_date
        },
        method: "POST",
        dataType: "json",
        headers: {
          Authorization: app.data.ifs_token
        }
      }).then(function ({
        data,
        xhr,
        status
      }) {
        app.dialog.close();
        self.$setState({
          tracking_nos_info: data.tracking_nos_info,
          shipmentCount: data.tracking_nos_info.length,
          db_shipmentCount: data.tracking_nos_info.length
        });
        self.$tick(() => {
          shipmentVirtualList.replaceAllItems(data.tracking_nos_info);
        });
      }).catch(function (error) {
        app.dialog.alert(`Error while fetching data. <br> Error: ${error.message}`, "Error");
      });
    },
    SaveTrackingNumberToDB: function (shipment_nos) {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$router; // const localforage = app.data.localforage

      let ship_date = document.querySelector("#ship_date").value;
      ship_date = new Date(`${ship_date}`).getTime() / 1000;
      ship_date = ship_date - ship_date % 86400;
      console.log("shipment_nos :", shipment_nos);
      let postdata = {
        w: "SaveScannedShipmentAWBNoV2",
        marketplace: "sf_amazon",
        shipment_nos: shipment_nos,
        ship_date: ship_date
      };
      console.log("postdata :", postdata); // app.dialog.preloader(
      //   `Saving Tracking No. to DB: ${shipment_no} ...`
      // )

      app.request.promise({
        url: app.data.apiUrl,
        data: postdata,
        method: "POST",
        dataType: "json",
        headers: {
          Authorization: app.data.ifs_token
        },
        timeout: app.data.defaultTimeout
      }).then(function ({
        data,
        xhr,
        status
      }) {
        app.dialog.close();

        if (data.result == 1) {
          self.$setState({
            db_shipmentCount: data.shipment_count
          });
          self.$tick(() => {// DOM updated
            // app.popup.close()
          });
        } else {// app.dialog.alert(
          //   `Error while saving data. <br> Error: ${data.error}`,
          //   "Error"
          // )
        }
      });
    },
    ShowForPrint: function () {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$router;
      let ship_date = document.querySelector("#ship_date").value;
      ship_date = new Date(`${ship_date}`).getTime() / 1000;
      ship_date = ship_date - ship_date % 86400;
      let href = `https://oms3.infiniteshopping.com/myapi/ifsreports/shipment_barcodes.php?ship_date=${ship_date}`;
      window.open(href, "_system");
    },
    ShowScanner: function () {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$router; // const localforage = app.data.localforage

      let shipmentVirtualList = app.virtualList.get(".shipment-list");
      let ship_date = document.querySelector("#ship_date").value;
      ship_date = new Date(`${ship_date}`).getTime() / 1000;
      ship_date = ship_date - ship_date % 86400;
      let marketplace = "sf_amazon";
      app.data.isBarcodeScannerOn = true;
      cordova.plugins.barcodeScanner.scan(function (result) {
        console.log("result :", result);

        if (result.cancelled == true) {
          app.data.isBarcodeScannerOn = false; // if (self.shipmentCount != self.db_shipmentCount) {
          //   localforage.getItem(`${marketplace}-${ship_date}-shipments}`).then((cached_tracking_nos) => {
          //     console.log('cached_tracking_nos :', cached_tracking_nos);
          //     var missing_tracking_nos = cached_tracking_nos.filter(function (x) {
          //       // checking second array does not contain element "x"
          //       if (self.tracking_nos.indexOf(x) == -1)
          //         return true;
          //       else
          //         return false;
          //     });
          //     //[1, 2]
          //     console.log(missing_tracking_nos);
          //     self.SaveTrackingNumberToDB(missing_tracking_nos)
          //   })
          // } else {
          //   localforage.clear().then(function () {
          //     // Run this code once the database has been entirely deleted.
          //     console.log('Database is now empty.');
          //   }).catch(function (err) {
          //     // This code runs if there were any errors
          //     console.log(err);
          //   });
          // }

          self.$tick();
        } else {
          function testJSON(text) {
            if (typeof text !== "string") {
              return false;
            }

            try {
              JSON.parse(text);
              return true;
            } catch (error) {
              return false;
            }
          }

          console.log("testJSON(result.text) :", testJSON(result.text));
          let shipmentNo;

          if (testJSON(result.text)) {
            shipmentNo = JSON.parse(result.text).cids.amazon_barcode;
          } else {
            shipmentNo = result.text;
          }

          console.log("shipmentNo1 :", shipmentNo);

          if (shipmentNo.includes("-")) {
            shipmentNo = shipmentNo.split("-")[1];
          }

          if (shipmentNo.includes("_")) {
            shipmentNo = shipmentNo.split("_")[0];
          }

          if (scan_result.slice(0, 1) == "S") {
            scan_result = scan_result.substr(1);
          }

          console.log("shipmentNo2 :", shipmentNo);
          let tracking_nos_info = self.tracking_nos_info;
          let index = tracking_nos_info.findIndex(i => i.shipment_id === shipmentNo);

          if (index !== -1) {
            app.data.isBarcodeScannerOn = false;
            cordova.plugins.barcodeScanner.exit();
            app.dialog.alert("Duplicate Shipment number found: " + shipmentNo, function () {
              self.ShowScanner();
            });
          } else {
            // window.plugins.toast.showWithOptions(
            //   {
            //     message: shipmentNo,
            //     duration: "short", // which is 2000 ms. "long" is 4000. Or specify the nr of ms yourself.
            //     position: "bottom",
            //     addPixelsY: -40  // added a negative value to move it up a bit (default 0)
            //   },
            //   function (a) { console.log('toast success: ' + a) },
            //   function (b) { console.log('toast error: ' + b) }
            // );
            let ship_date = document.querySelector("#ship_date").value;
            ship_date = new Date(`${ship_date}`).getTime() / 1000;
            ship_date = ship_date - ship_date % 86400;
            console.log("tracking_nos :", [shipmentNo.trim()]);
            let postdata = {
              w: "SaveScannedShipmentAWBNoV2",
              marketplace: "sf_amazon",
              shipment_nos: [shipmentNo.trim()],
              ship_date: ship_date
            }; // console.log('postdata :', postdata);

            app.request.promise({
              url: app.data.apiUrl,
              data: postdata,
              method: "POST",
              dataType: "json",
              headers: {
                Authorization: app.data.ifs_token
              },
              timeout: app.data.defaultTimeout
            }).then(function ({
              data,
              xhr,
              status
            }) {
              console.log("data :", data);

              if (data.result == 1) {
                let tracking_nos_info = [...self.tracking_nos_info, {
                  shipment_id: shipmentNo.trim(),
                  tracking_no: ""
                }];
                self.$setState({
                  db_shipmentCount: data.shipment_count,
                  shipment_nos: tracking_nos_info,
                  shipmentCount: tracking_nos_info.length
                });
                shipmentVirtualList.prependItem({
                  shipment_id: shipmentNo.trim(),
                  tracking_no: ""
                });
              } else {
                app.data.isBarcodeScannerOn = false;
                cordova.plugins.barcodeScanner.exit();
                app.dialog.alert(data.error, function () {
                  self.ShowScanner();
                });
              }
            }).catch(function (error) {
              console.log("error :", error);
              app.data.isBarcodeScannerOn = false;
              cordova.plugins.barcodeScanner.exit();
              app.dialog.alert("Insert Failed... Try Again... " + shipmentNo);
            }); // localforage.setItem(`${marketplace}-${ship_date}-shipments}`, tracking_nos).then(function (value) {
            //   // Do other things once the value has been saved.
            //   // console.log(value);
            // }).catch(function (err) {
            //   // This code runs if there were any errors
            //   // console.log(err);
            // });

            self.$tick(() => {});
          }
        }
      }, function (error) {
        // app.data.isBarcodeScannerOn = false;
        app.dialog.alert("Scanning failed: " + error);
      }, {
        preferFrontCamera: false,
        // iOS and Android
        showFlipCameraButton: false,
        // iOS and Android
        showTorchButton: true,
        // iOS and Android
        torchOn: false,
        // Android, launch with the torch switched on (if available)
        saveHistory: false,
        // Android, save scan history (default false)
        prompt: "Place the QRCODE inside the scan area",
        // Android
        resultDisplayDuration: 500,
        // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
        formats: "DATA_MATRIX",
        // default: all but PDF_417 and
        disableAnimations: true,
        // iOS
        disableSuccessBeep: false,
        // iOS and Android
        continuousMode: true // Android

      });
    }
  },
  // orientation: "portrait", // Android only (portrait|landscape), default unset so it rotates with the device
  // Page Events
  on: {
    pageMounted: function (e, page) {
      console.log("pageMounted", page);
    },
    pageInit: function (e, page) {
      console.log("pageInit", page);
    },
    pageBeforeIn: function (e, page) {
      console.log("pageBeforeIn", page);
    },
    pageAfterIn: function (e, page) {
      console.log("pageAfterIn", page);
    },
    pageBeforeOut: function (e, page) {
      console.log("pageBeforeOut", page);
    },
    pageAfterOut: function (e, page) {
      console.log("pageAfterOut", page);
    },
    pageBeforeRemove: function (e, page) {
      console.log("pageBeforeRemove", page);
    }
  },
  id: '8f676d7e85',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=shipment-photos><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=ios-only>Back</span></a></div><div class="title list inline-labels no-hairlines-md"><div class=item-inner><div class=item-input-wrap><input type=date name=ship_date id=ship_date @change=ChangeShipDate value="';
      r += c(ctx_1.shipDate, ctx_1);
      r += '"></div><div class="item-title item-label">(';
      r += c(ctx_1.shipmentCount, ctx_1);
      r += ')</div></div></div><div class=right><!-- Link to enable searchbar --> <a class="link icon-only searchbar-enable" data-searchbar=.searchbar><i class="icon f7-icons if-not-md">search</i> <i class="icon material-icons md-only">search</i></a></div><!--         <form class="searchbar searchbar-expandable">\n              <div class="searchbar-inner">\n                <div class="searchbar-input-wrap">\n                  <input type="search" placeholder="Search"/>\n                  <i class="searchbar-icon"></i>\n                  <span class="input-clear-button"></span>\n                </div>\n                <span class="searchbar-disable-button">Cancel</span>\n              </div>\n            </form> --><form data-search-container=.shipment-list data-search-item=li data-search-in=.item-title class="searchbar searchbar-init searchbar-expandable"><div class=searchbar-inner><div class=searchbar-input-wrap><input type=search placeholder=Search> <i class=searchbar-icon></i> <span class=input-clear-button></span></div><span class="searchbar-disable-button if-not-aurora">Cancel</span></div></form><!-- <div class="right">\n              <a class="link popover-open mp-filter" href="#" data-picklist-id="';
      r += c(ctx_1.selectPicklistId, ctx_1);
      r += '" data-mp-attr="';
      r += c(ctx_1.selectMarketplace, ctx_1);
      r += '" data-popover=".popover-links">';
      r += c(ctx_1.selectMarketplaceName, ctx_1);
      r += '</a>\n            </div> --></div></div><div class="fab fab-extended fab-right-bottom"><a class="fab color-purple" @click=ShowScanner><i class="icon f7-icons size-32">qrcode</i></a></div><div class="fab fab-extended fab-center-bottom"><a class="fab color-red" @click=ShowForPrint><i class="icon f7-icons size-32">printer</i></a></div><div class=page-content><div class=ptr-preloader><div class=preloader></div><div class=ptr-arrow></div></div><div class=searchbar-backdrop></div><div class="list simple-list searchbar-not-found"><ul><li>Nothing found</li></ul></div><div class="list virtual-list shipment-list media-list ptr-watch-scrollable simple-list" style="margin-top: 0"></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};