import HomePage from "../pages/home.f7.html"
import AboutPage from "../pages/about.f7.html"
import FormPage from "../pages/form.f7.html"

import SnapperPage from "../pages/snapper.f7.html"
import ReturnVideosPage from "../pages/return-videos.f7.html"
import LabelImagesPage from "../pages/label-images.f7.html"

import ShipmentCountCheckPage from "../pages/shipmentcheck.f7.html"
import StartInwardPage from "../pages/startinward.f7.html"
import AwbInwardPage from "../pages/awbinward.f7.html"
import ShowBarcodesPage from "../pages/showbarcodes.f7.html"
import RackCheckSelectPage from "../pages/rackcheckselect.f7.html"
import RackCheckPage from "../pages/rackcheck.f7.html"
import RackFindShiftPage from "../pages/rackfindshift.f7.html"
import RackShiftPage from "../pages/rackshift.f7.html"
import ProductDetailsPage from "../pages/productdetails.f7.html"
import AWBScanPage from "../pages/awb-scan.f7.html"
import ReturnToRackPage from "../pages/return-to-rack.f7.html"

import DynamicRoutePage from "../pages/dynamic-route.f7.html"
import RequestAndLoad from "../pages/request-and-load.f7.html"
import NotFoundPage from "../pages/404.f7.html"
import InstapickPage from "../pages/instapick.f7.html"

import ViewShipmentImagesPage from "../pages/view-shipment-images.f7.html"

var routes = [
  {
    path: "/",
    component: HomePage,
  },
  {
    path: "/viewshipmentimages/",
    component: ViewShipmentImagesPage,
  },
  {
    path: "/awbscan/",
    async: function (routeTo, routeFrom, resolve, reject) {
      var router = this
      // App instance
      var app = router.app
      // Show Preloader
      app.preloader.show()

      let recd_date = new Date().getTime() / 1000
      recd_date = recd_date - (recd_date % 86400)
      app.request
        .promise({
          url: app.data.apiUrl,
          data: {
            remoteSection: "ims",
            w: "GetAWBInwardInfo",
            recd_date: recd_date,
          },
          method: "POST",
          dataType: "json",
          headers: {
            Authorization: app.data.ifs_token,
          },
        })
        .then(function ({ data, xhr, status }) {
          console.log(data)
          // console.log('SON.parse(data.json) :', JSON.parse(data.json))
          app.preloader.hide()
          resolve(
            {
              component: AWBScanPage,
            },
            {
              context: {
                partialAirwayBills: data.partial_awbs,
                completedAirwayBills: data.completed_awbs,
              },
            }
          )
        })
        .catch(function (error) {
          app.preloader.hide()

          if (error.status == 401) {
            app.loginScreen.open("#my-login-screen")
          } else {
            app.dialog.alert("AJAX Error: " + error.message)
          }
        })
    },
  },
  {
    path: "/returntorack/",
    async: function (routeTo, routeFrom, resolve, reject) {
      var router = this
      // App instance
      var app = router.app
      // Show Preloader
      app.preloader.show()

      app.request
        .promise({
          url: app.data.apiUrl,
          data: {
            remoteSection: "ims",
            w: "GetPendingReturnToRack",
          },
          method: "POST",
          dataType: "json",
          headers: {
            Authorization: app.data.ifs_token,
          },
        })
        .then(function ({ data, xhr, status }) {
          console.log("GetPendingReturnToRack", data)
          // console.log('SON.parse(data.json) :', JSON.parse(data.json))
          app.preloader.hide()
          resolve(
            {
              component: ReturnToRackPage,
            },
            {
              context: {
                returns: data.json,
              },
            }
          )
        })
        .catch(function (error) {
          app.preloader.hide()

          if (error.status == 401) {
            app.loginScreen.open("#my-login-screen")
          } else {
            app.dialog.alert("AJAX Error: " + error.message)
          }
        })
    },
  },
  {
    path: "/productdetails/",
    component: ProductDetailsPage,
  },
  {
    path: "/startinward/",
    component: StartInwardPage,
  },
  {
    path: "/snapper/",
    component: SnapperPage,
  },
  {
    path: "/returnvideos/",
    component: ReturnVideosPage,
  },
  {
    path: "/labelimages/",
    component: LabelImagesPage,
  },
  {
    path: "/shipmentcountcheck/",
    component: ShipmentCountCheckPage,
  },
  {
    path: "/showbarcodes/",
    component: ShowBarcodesPage,
  },
  {
    path: "/rackfind/",
    master: true,
    async: function (routeTo, routeFrom, resolve, reject) {
      resolve(
        {
          component: RackFindShiftPage,
        },
        {
          context: {
            pageName: "RackFind",
            shift: false,
          },
        }
      )
    },
  },
  {
    path: "/rackshift/",
    master: true,
    async: function (routeTo, routeFrom, resolve, reject) {
      var router = this
      var app = router.app
      resolve(
        {
          component: RackFindShiftPage,
        },
        {
          context: {
            pageName: "RackShift",
            shift: true,
          },
        }
      )
    },
  },
  {
    path: "/rackshiftnew/",
    master: true,
    async: function (routeTo, routeFrom, resolve, reject) {
      var router = this
      var app = router.app
      resolve(
        {
          component: RackShiftPage,
        },
        {
          context: {
            pageName: "RackShift",
          },
        }
      )
    },
  },
  {
    path: "/rackcheckselect/",
    component: RackCheckSelectPage,
  },
  {
    path: "/rackcheck/:rackid",
    async: function (routeTo, routeFrom, resolve, reject) {
      var router = this
      // App instance
      var app = router.app
      // Show Preloader
      app.preloader.show()
      app.request
        .promise({
          url: app.data.apiUrl,
          data: {
            remoteSection: "ims",
            w: "GetRackContents",
            rackid: routeTo.params.rackid,
          },
          method: "POST",
          dataType: "json",
          headers: {
            Authorization: app.data.ifs_token,
          },
        })
        .then(function ({ data, xhr, status }) {
          console.log(data)
          // console.log('SON.parse(data.json) :', JSON.parse(data.json))
          app.preloader.hide()
          resolve(
            {
              component: RackCheckPage,
            },
            {
              context: {
                rackCheckItems: JSON.parse(data.json),
              },
            }
          )
        })
        .catch(function (error) {
          app.preloader.hide()

          if (error.status == 401) {
            app.loginScreen.open("#my-login-screen")
          } else {
            app.dialog.alert("AJAX Error: " + error.message)
          }
        })
    },
  },
  {
    path: "/dynamic-route/blog/:blogId/post/:postId/",
    component: DynamicRoutePage,
  },
  {
    path: "/awbinward/:airwaybillno",
    async: function (routeTo, routeFrom, resolve, reject) {
      var router = this
      // App instance
      var app = router.app
      // Show Preloader
      app.preloader.show()
      app.request
        .promise({
          url: app.data.apiUrl,
          data: {
            remoteSection: "ims",
            w: "GetAirwayBillJsonInfo",
            airwaybillno: routeTo.params.airwaybillno,
          },
          method: "POST",
          dataType: "json",
          headers: {
            Authorization: app.data.ifs_token,
          },
        })
        .then(function ({ data, xhr, status }) {
          console.log(data)
          app.preloader.hide()
          resolve(
            {
              component: AwbInwardPage,
            },
            {
              context: {
                awb_json: data,
              },
            }
          )
        })
        .catch(function (error) {
          app.preloader.hide()

          if (error.status == 401) {
            app.loginScreen.open("#my-login-screen")
          } else {
            app.dialog.alert("AJAX Error: " + error.message)
          }
        })
    },
  },

  {
    path: "/instapick/:marketplace",
    async: function (routeTo, routeFrom, resolve, reject) {
      // console.log(routeTo.params.marketplace)
      let days = 0
      if (routeTo.query.days) {
        days = routeTo.query.days
      }
      var router = this

      // App instance
      var app = router.app

      // Show Preloader
      app.preloader.show()
      app.request
        .promise({
          url: app.data.apiUrl,
          data: {
            remoteSection: "ims",
            w: "GetPicklistInfoJson",
            days: days,
            m: routeTo.params.marketplace,
          },
          method: "POST",
          dataType: "json",
          headers: {
            Authorization: app.data.ifs_token,
          },
        })
        .then(function ({ data, xhr, status }) {
          // console.log(data);
          app.preloader.hide()
          resolve(
            // How and what to load: template
            {
              component: InstapickPage,
            },
            // Custom template context
            {
              context: {
                instapick_json: data,
              },
            }
          )
        })
        .catch(function (error) {
          app.preloader.hide()

          if (error.status == 401) {
            app.loginScreen.open("#my-login-screen")
          } else {
            app.dialog.alert("AJAX Error: " + error.message)
          }
        })
    },
  },
  {
    path: "/instapick/sellerflexpicklist/:picklistid",
    async: function (routeTo, routeFrom, resolve, reject) {
      let days = 0
      if (routeTo.query.days) {
        days = routeTo.query.days
      }
      var router = this

      // App instance
      var app = router.app
      // Show Preloader
      app.preloader.show()
      app.request
        .promise({
          url: app.data.apiUrl,
          data: {
            remoteSection: "ims",
            w: "GetPicklistInfoJson",
            days: days,
            m: "sellerflexpicklist",
            picklistid: routeTo.params.picklistid,
          },
          method: "POST",
          dataType: "json",
          headers: {
            Authorization: app.data.ifs_token,
          },
          timeout: app.data.defaultTimeout,
        })
        .then(function ({ data, xhr, status }) {
          app.preloader.hide()
          resolve(
            // How and what to load: template
            {
              component: InstapickPage,
            },
            // Custom template context
            {
              context: {
                instapick_json: data,
              },
            }
          )
        })
        .catch(function (error) {
          app.preloader.hide()

          if (error.status == 401) {
            app.loginScreen.open("#my-login-screen")
          } else {
            app.dialog.alert("AJAX Error: " + error.message)
          }
        })
    },
  },
  {
    path: "/request-and-load/user/:userId/",
    async: function (routeTo, routeFrom, resolve, reject) {
      // Router instance
      var router = this

      // App instance
      var app = router.app

      // Show Preloader
      app.preloader.show()

      // User ID from request
      var userId = routeTo.params.userId

      // Simulate Ajax Request
      setTimeout(function () {
        // We got user data from request
        var user = {
          firstName: "Vladimir",
          lastName: "Kharlampidi",
          about: "Hello, i am creator of Framework7! Hope you like it!",
          links: [
            {
              title: "Framework7 Website",
              url: "http://framework7.io",
            },
            {
              title: "Framework7 Forum",
              url: "http://forum.framework7.io",
            },
          ],
        }
        // Hide Preloader
        app.preloader.hide()

        // Resolve route to load page
        resolve(
          {
            component: RequestAndLoad,
          },
          {
            context: {
              user: user,
            },
          }
        )
      }, 1000)
    },
  },
  {
    path: "(.*)",
    component: NotFoundPage,
  },
]

export default routes
