
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      Template7.registerPartial('order-product', '<li class="swipeout">    <div class="swipeout-content">      <div class="item-content">        <div class="item-media">          <!-- prettier-ignore -->          <img src="https://cdn.infiniteshopping.com/cache/175x175-0/static/{{image}}?1" width="80" />        </div>        <div class="item-inner">          <div class="item-title-row">            <div class="item-title">{{ title }}</div>            <div class="item-after">{{ qty }}</div>          </div>          <div class="item-subtitle">Order ID: {{ order_id }}</div>          <div class="item-subtitle">            Marketplace OID:            {{ marketplace_order_id }}          </div>          <div class="item-subtitle">            SKU: {{ sku }} PID:            {{ product_id }}          </div>          <div class="item-text" style="overflow: visible;">            <ul>              {{#each expiryInfo}}              <li>Qty: {{ qty }} - Expiry: {{ expiry }}</li>              {{/each}}            </ul>          </div>        </div>      </div>    </div>    <div class="swipeout-actions-left">      <a class="color-purple swipeout-overswipe" @click="ShowProductInfo">Product Info</a>    </div>    <!-- <div class="swipeout-actions-right">      s      <a class="color-green swipeout-overswipe" @click="TakePhoto({{@index}})"        >Take Photo</a      >    </div> -->  </li>  <!-- <li>    <div class="thumbImages">      {{#each cameraImages}}      <img        @taphold.native="DeleteImage({{ ../@index}},{{ @index }})"        @click="ShowLargeImage({{ ../@index}},{{ @index }})"        style="width:100px;"        src="{{this}}"        alt=""      />      {{/each}}    </div>  </li> -->');
  
      import util from "util";
export default {
  // Lifecycle Hooks
  beforeCreate() {
    console.log("componentBeforeCreate", this);
  },

  created() {
    console.log("componentCreated", this);
  },

  beforeMount() {
    console.log("componentBeforeMount", this);
  },

  mounted() {
    console.log("componentMounted", this);
    const self = this;
    const app = self.$app;
    const $ = self.$$;
    const router = self.$router;
  },

  beforeDestroy() {
    console.log("componentBeforeDestroy", this);
  },

  destroyed() {
    console.log("componentDestroyed", this);
  },

  // Component Data
  data: function () {
    // Must return an object
    return {
      orderProducts: [],
      order_id_text: "",
      cameraVideos: [],
      photo_iid: 0
    };
  },
  // Component Methods
  methods: {
    openAlert: function () {
      var self = this;
      self.$app.dialog.alert("Hello World");
    },
    ShowProductInfo: function () {},
    ShowVideo: function (id) {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$router;
      VideoPlayer.play(self.cameraVideos[id], {
        volume: 1 // scalingMode: VideoPlayer.SCALING_MODE.SCALE_TO_FIT_WITH_CROPPING

      }, function () {
        console.log("video completed");
      }, function (err) {
        console.log(err);
      });
    },
    DeleteImage: function (id) {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$router;
      app.dialog.confirm("Delete this image?", "Warning", function () {
        self.cameraVideos.splice(id, 1);
        self.$setState({
          cameraVideos: self.cameraVideos
        });
      }, function () {});
    },
    SavePhotos: async function () {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$routers;

      if (!self.cameraVideos.length) {
        app.dialog.alert("No photos to save :)", "Info");
        return;
      }

      function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

      let dialog = app.dialog.progress("Saving videos on server");
      let fileNameOrderInfo = `${self.orderProducts[0].order_invoice_id}_${self.orderProducts[0].marketplace}_${self.orderProducts[0].order_id}_${self.orderProducts[0].marketplace_order_id}`;
      let fileNameProductsInfo = [];
      self.orderProducts.forEach((product, pid) => {
        fileNameProductsInfo.push(`${product.sku}_(${product.qty})`);
      });
      let fileNamePrefix = `${fileNameOrderInfo}___${fileNameProductsInfo.join("___")}`;

      function getExtension(filename) {
        return filename.substring(filename.lastIndexOf(".") + 1, filename.length) || filename;
      }

      var fd = new FormData();

      function addFormData(file, newFileName) {
        return new Promise((resolve, reject) => {
          var reader = new FileReader();

          reader.onloadend = function (e) {
            var imgBlob = new Blob([this.result], {
              type: file.type
            });
            console.log("imgBlob :", imgBlob);
            fd.append("attachments[]", imgBlob, newFileName);
            console.log("fd :", fd);
            resolve();
          };

          reader.readAsArrayBuffer(file);
        });
      }

      function moveFiles() {
        return self.cameraVideos.map((path, iid) => {
          return new Promise(async (resolve, reject) => {
            console.log("path :", path);
            let extension = getExtension(path);
            console.log("extension :", extension);

            window.resolveLocalFileSystemURL[util.promisify.custom] = imageData => {
              return new Promise((resolve, reject) => {
                window.resolveLocalFileSystemURL(imageData, resolve, reject);
              });
            };

            const resolveLocalFileSystemURL = util.promisify(window.resolveLocalFileSystemURL);
            let entry = await resolveLocalFileSystemURL(path);
            console.log("entry:", entry);
            let newFileName = `${fileNamePrefix}-${iid + self.photo_iid + 1}.${extension}`; // prettier-ignore

            let saveFolderName = "Documents/" + new Date().toISOString().substring(0, 10);
            let fileSystem = await resolveLocalFileSystemURL(cordova.file.externalRootDirectory);

            fileSystem.getDirectory[util.promisify.custom] = (saveFolderName, params) => {
              return new Promise((resolve, reject) => {
                fileSystem.getDirectory(saveFolderName, params, resolve, reject);
              });
            };

            const getDirectory = await util.promisify(fileSystem.getDirectory);
            let directory = await getDirectory(saveFolderName, {
              create: true,
              exclusive: false
            });
            console.log("directory :", directory);

            entry.copyTo[util.promisify.custom] = (directory, newFileName) => {
              return new Promise((resolve, reject) => {
                entry.copyTo(directory, newFileName, resolve, reject);
              });
            };

            await entry.copyTo(directory, newFileName);
            console.log("${saveFolderName}/${newFileName} :", `${saveFolderName} / ${newFileName}`);

            entry.file[util.promisify.custom] = () => {
              return new Promise((resolve, reject) => {
                entry.file(resolve, reject);
              });
            };

            const getFileData = util.promisify(entry.file);
            let fileData = await getFileData();
            console.log("fileData :", fileData);
            console.log("entry.toURL() :", entry.toURL());
            resolve(splitUploadFile(entry.toURL(), fileData, newFileName)); // return `${newFileName}`
          });
        });

        function splitUploadFile(path, file, filename) {
          return new Promise((resolve, reject) => {
            var success = function (r) {
              console.log("Successful upload...");
              console.log("Code = " + r.responseCode);
              resolve(); // displayFileData(fileEntry.fullPath + " (content uploaded to server)");
            };

            var fail = function (error) {
              alert("An error has occurred: Code = " + error.code);
            };

            var options = new FileUploadOptions();
            options.fileKey = "attachments[]";
            options.fileName = filename;
            options.mimeType = file.type;
            let date = new Date().toISOString().substring(0, 10);
            var params = {};
            params.date = date;
            options.params = params;
            var ft = new FileTransfer();
            let trustAllHosts = true; // SERVER must be a URL that can handle the request, like
            // http://some.server.com/upload.php

            ft.upload(path, encodeURI(`http://192.168.0.130/savereturnvideos.php`), success, fail, options, trustAllHosts);
          }); // !! Assumes variable fileURL contains a valid URL to a text file on the device,
        }
      }

      let movedFiles = await moveFiles();
      Promise.all(movedFiles) // .then(function () {
      //   return new Promise((resolve, reject) => {
      //     console.log('fd :', fd);
      //     let uploads = fd.getAll('attachments[]')
      //     console.log('uploads :', uploads);
      //     console.log("all things done");
      //     var request = new XMLHttpRequest();
      //     request.timeout = 60000;
      //     request.addEventListener("progress", updateProgress);
      //     request.addEventListener("load", function (event) {
      //       console.log("The transfer is complete.");
      //       resolve('uploaded')
      //     });
      //     request.addEventListener("error", transferFailed);
      //     request.addEventListener("abort", transferCanceled);
      //     let date = new Date().toISOString().substring(0, 10)
      //     request.open('POST', `http://192.168.0.130/savereturnvideos.php?date=${date}`, true);
      //     request.send(fd);
      //   });
      // })
      .then(() => {
        self.$setState({
          orderProducts: [],
          order_id_text: "",
          cameraVideos: [],
          photo_iid: 0
        });
        app.dialog.close();
        app.dialog.alert("Videos Saved/Uploaded..", "Success");
      });
    },
    TakeVideo: function () {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$router;
      console.log("self.orderProducts.length :", self.orderProducts.length);
      console.log("self.orderProducts :", self.orderProducts);

      if (!self.orderProducts.length) {
        app.dialog.alert("Find the Return order first :)", "Info");
        return;
      } else {
        var options = {
          limit: 1
        };
        navigator.device.capture.captureVideo(onSuccess, onError, options);

        function onSuccess(mediaFiles) {
          var i, path, len;

          for (i = 0, len = mediaFiles.length; i < len; i += 1) {
            path = mediaFiles[i].fullPath;
            self.cameraVideos.push(path);
            self.$setState({
              cameraVideos: self.cameraVideos
            });
            console.log("path :", path);
            console.log("mediaFiles :", mediaFiles);
          }
        }

        function onError(error) {
          navigator.notification.alert("Error code: " + error.code, null, "Capture Error");
        }
      }
    },
    TakePhoto: function () {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$router;
      console.log("self.orderProducts.length :", self.orderProducts.length);
      console.log("self.orderProducts :", self.orderProducts);

      if (!self.orderProducts.length) {
        app.dialog.alert("Find the Return order first :)", "Info");
        return;
      } else {
        var options = {
          limit: 1,
          quality: 70
        };
        navigator.device.capture.captureImage(onSuccess, onError, options);

        function onSuccess(mediaFiles) {
          var i, path, len;

          for (i = 0, len = mediaFiles.length; i < len; i += 1) {
            path = mediaFiles[i].fullPath;
            self.cameraVideos.push(path);
            self.$setState({
              cameraVideos: self.cameraVideos
            });
            console.log("path :", path);
            console.log("mediaFiles :", mediaFiles);
          }
        }

        function onError(error) {
          navigator.notification.alert("Error code: " + error.code, null, "Capture Error");
        }
      }
    },
    GetProductInfo: function (postdata) {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$router;
      app.dialog.preloader(`Fetching Data`);
      app.request.promise({
        url: app.data.apiUrl,
        data: postdata,
        method: "POST",
        dataType: "json",
        headers: {
          Authorization: app.data.ifs_token
        },
        timeout: app.data.defaultTimeout
      }).then(function ({
        data,
        xhr,
        status
      }) {
        console.log(data);
        let orderProducts = data.json.map(each => {
          each.expiryInfo = each.expiry_info.split(",").map(item => {
            // TODO if arr undefined means item not picked...
            let arr = item.split("|");
            return {
              qty: arr[0],
              expiry: arr[1]
            };
          });
          return each;
        });
        console.log("orderProducts :", orderProducts);
        app.dialog.close();

        if (data.result == 1) {
          self.$setState({
            orderProducts: data.json,
            order_id_text: `Order Id: ${data.json[0].order_id}`
          });
          self.$tick(() => {
            $(".find-order, .login-screen-content").hide();
            $(".save-photos").show();
          });
        } else {
          app.dialog.alert(`Error while fetching data. <br> Error 1: ${data.error}`, "Error");
        }
      }).catch(function (error) {
        app.dialog.close();
        app.dialog.alert(`Error while fetching data. <br> Error 2: ${error.message}`, "Error");
      });
    },
    FindOrder: function () {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$router;
      app.dialog.preloader(`Fetching Data`);
      let post_data = { ...app.form.convertToData("#order-find-form"),
        remoteSection: "ims",
        w: "GetReturnOrderInfo"
      };
      let order_invoice_id = $("#order_invoice_id").val(); // get local images

      let postdata_videos = {
        searchterm: order_invoice_id,
        calendarrange: ""
      };
      let postdata = {
        remoteSection: "ims",
        w: "GetReturnOrderInfo",
        order_invoice_id
      };
      self.$setState({
        photo_iid: 0
      });
      self.$tick(() => {
        app.request.promise({
          url: "http://192.168.0.130/getreturnvideos.php",
          data: postdata_videos,
          method: "POST",
          dataType: "json",
          timeout: app.data.defaultTimeout
        }).then(function ({
          data,
          xhr,
          status
        }) {
          app.dialog.close();

          if (data.result == 1) {
            if (data.return_videos.length) {
              app.dialog.confirm(`${data.return_videos.length} Videos already taken. Take more videos?`, "Warning", function () {
                self.$setState({
                  photo_iid: data.return_videos.length
                });
                self.$tick(() => {
                  self.GetProductInfo(postdata);
                });
              }, function () {
                self.$setState({
                  photo_iid: 0
                });
                return;
              });
            }
          } else {
            self.$tick(() => {
              self.GetProductInfo(postdata);
            });
          }
        }).catch(function (error) {
          app.dialog.close();
          app.dialog.alert(`Error while fetching data. <br> Error 2: ${error.message}`, "Error");
        });
      });
    },
    Restart: function () {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$router;
      self.$setState({
        orderProducts: [],
        order_id_text: "",
        cameraVideos: [],
        photo_iid: 0
      });
      $(".find-order, .login-screen-content").show();
      $(".find-order").val("");
      $(".save-photos").hide();
    }
  },
  // Page Events
  on: {
    pageMounted: function (e, page) {
      console.log("pageMounted", page);
    },
    pageInit: function (e, page) {
      console.log("pageInit", page);
    },
    pageBeforeIn: function (e, page) {
      console.log("pageBeforeIn", page);
    },
    pageAfterIn: function (e, page) {
      console.log("pageAfterIn", page);
    },
    pageBeforeOut: function (e, page) {
      console.log("pageBeforeOut", page);
    },
    pageAfterOut: function (e, page) {
      console.log("pageAfterOut", page);
    },
    pageBeforeRemove: function (e, page) {
      console.log("pageBeforeRemove", page);
    }
  },
  id: '2ba8a366a6',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=shipment-photos><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Back</span></a></div><div class=title>Return Videos - ';
      r += c(ctx_1.order_id_text, ctx_1);
      r += '</div><div class=right><a class="link color-green save-photos" style="display: none" @click=SavePhotos>Save </a><a class="link color-green find-order" @click=FindOrder>Find</a></div></div></div><div class="fab fab-extended fab-right-bottom"><a class="color-green margin-bottom" @click=SavePhotos><i class="icon f7-icons size-32">cloud_upload</i> </a><a class="fab color-purple" @click=Restart><i class="icon f7-icons size-32">arrow_2_circlepath</i></a></div><div class="fab fab-extended fab-left-bottom color-red"><a @click=TakePhoto><i class="icon f7-icons size-32">camera_fill</i></a></div><div class="fab fab-extended fab-center-bottom color-blue"><a @click=TakeVideo><i class="icon f7-icons size-32">videocam_fill</i></a></div><div class=page-content><div class=login-screen-content><div class=login-screen-title>Enter Order Id</div><form class=list id=order-find-form><ul><li class="item-content item-input"><div class=item-inner><div class="item-title item-floating-label">QWQC for amazon</div><div class=item-input-wrap><input type=text name=order_invoice_id id=order_invoice_id style="font-size: 30px; height: auto"> <span class=input-clear-button></span></div></div></li></ul></form></div><div class="list media-list"><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.orderProducts, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers._partial.call(ctx_2, "order-product", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div><div class=block><div class=thumbImages>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.cameraVideos, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.js_if.call(ctx_2, "this.substring(this.lastIndexOf('.') + 1, this.length) == 'jpg'", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' <img @taphold.native="DeleteImage(';
              r += c(data_3 && data_3.index, ctx_3);
              r += ')" @click="ShowVideo(';
              r += c(data_3 && data_3.index, ctx_3);
              r += ')" style="width: 100px" src=';
              r += c(ctx_3, ctx_3);
              r += ' alt=""> ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '<video @taphold.native="DeleteImage(';
              r += c(data_3 && data_3.index, ctx_3);
              r += ')" @click="ShowVideo(';
              r += c(data_3 && data_3.index, ctx_3);
              r += ')" style="height: 100px" src=';
              r += c(ctx_3, ctx_3);
              r += '></video>';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    