
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      Template7.registerPartial('return-item', '<li class="order-item swipeout">    <div class="swipeout-content">      <div class="item-content">        <div class="item-media">          <!-- prettier-ignore -->          <img src="https://cdn.infiniteshopping.com/cache/80x80-0/static/{{image}}?1" width="80" />        </div>        <div class="item-inner">          <div class="item-title-row">            <div class="item-title">{{ prodname }}</div>            <div class="item-after">{{ retprodqty }}</div>          </div>          <div class="item-subtitle">Order ID: {{ retorderid }}</div>          <div class="item-subtitle">            Marketplace OID: {{ marketplace_order_id }}          </div>          <div class="item-subtitle">SKU: {{ sku }} PID: {{ retorderid }}</div>          <div class="item-text">Expiry Info: {{ expiry }}</div>          <div class="item-text">Return Recd Date: {{ returndate }}</div>          {{#if disabled}}          <div class="item-text text-color-red">SKU DISABLED</div>          {{/if}}        </div>      </div>    </div>    {{#if disabled }} {{#if @root.isAdmin}}    <div class="swipeout-actions-right">      <a        class="color-pink swipeout-overswipe"        @click="AddToRack({{ returnid }})"        >Add To Rack</a      >    </div>    {{/if}} {{else}}    <div class="swipeout-actions-right">      <a        class="color-pink swipeout-overswipe"        @click="AddToRack({{ returnid }})"        >Add To Rack</a      >    </div>    {{/if}}  </li>');
  
      export default {
  // Lifecycle Hooks
  beforeCreate() {
    console.log("componentBeforeCreate", this);
  },

  created() {
    console.log("componentCreated", this);
  },

  beforeMount() {
    console.log("componentBeforeMount", this);
  },

  mounted() {
    console.log("componentMounted", this);
    const self = this;
    const app = self.$app;
    const $ = self.$$;
    const router = self.$router;
  },

  beforeDestroy() {
    console.log("componentBeforeDestroy", this);
  },

  destroyed() {
    console.log("componentDestroyed", this);
  },

  // Component Data
  data: function () {
    // Must return an object
    const self = this;
    console.log("self.$app.data :>> ", self.$app.data);
    return {
      isAdmin: self.$app.data.userName === "admin" ? 1 : 0
    };
  },
  // Component Methods
  methods: {
    openAlert: function () {
      var self = this;
      self.$app.dialog.alert("Hello World");
    },
    AddToRack: function (returnid) {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$router;
      let currentItemIndex = self.returns.findIndex(row => {
        return row.returnid == returnid;
      });
      let currentItem = self.returns[currentItemIndex];
      console.log("currentItemIndex :", currentItemIndex);
      let toRackId = "R11";

      if (currentItem.marketplace == "sf_amazon") {
        toRackId = "R12";
      }

      if (currentItem.marketplace == "sf_flipkart") {
        toRackId = "R13";
      }

      let dialogText = `Marketplace:${currentItem.marketplace}<br>
        OrderId:${currentItem.retorderid} / ${currentItem.marketplace_order_id}<br>Adding to Rack: ${toRackId}<br>SKU: ${currentItem.sku}<br>Qty: ${currentItem.retprodqty}<br>Expiry:${currentItem.expiry}`;
      let dialogTitle = "Confirm Return to Rack?";
      app.dialog.confirm(dialogText, dialogTitle, function () {
        let json_post_data = {
          product_id: currentItem.retprodid,
          order_id: currentItem.retorderid,
          return_pick_id: currentItem.rack_log_pick_id,
          to_rack_id: toRackId,
          to_listing_sku_id: currentItem.listing_sku_id_db,
          to_marketplace: currentItem.marketplace,
          to_rack_qty: currentItem.retprodqty,
          notes: "return",
          expiry: currentItem.expiry_timestamp,
          checkForOtherItems: 1
        };
        let postdata = {
          remoteSection: "ims",
          w: "DoReturnToRack",
          data: json_post_data
        };
        app.dialog.preloader("Adding to return rack...");
        app.request.promise({
          url: app.data.apiUrl,
          data: postdata,
          method: "POST",
          dataType: "json",
          headers: {
            Authorization: app.data.ifs_token
          },
          timeout: app.data.defaultTimeout
        }).then(function ({
          data,
          xhr,
          status
        }) {
          app.dialog.close();

          if (data.result == 1) {
            self.returns.splice(currentItemIndex, 1);
            self.$setState({
              returns: self.returns
            });
            self.$tick(() => {
              // DOM updated
              app.dialog.alert(`Please keep the return item on <br> RACK ID: ${toRackId}`, "Success");
            });
          } else {
            app.dialog.alert(`Error while saving data. <br> Error: ${data.error}`, "Error");
          }
        }).catch(function (error) {
          app.dialog.close();
          app.dialog.alert("AJAX Error: " + error.message);
        });
      });
    }
  },
  // Page Events
  on: {
    pageMounted: function (e, page) {
      console.log("pageMounted", page);
    },
    pageInit: function (e, page) {
      console.log("pageInit", page);
    },
    pageBeforeIn: function (e, page) {
      console.log("pageBeforeIn", page);
    },
    pageAfterIn: function (e, page) {
      console.log("pageAfterIn", page);
    },
    pageBeforeOut: function (e, page) {
      console.log("pageBeforeOut", page);
    },
    pageAfterOut: function (e, page) {
      console.log("pageAfterOut", page);
    },
    pageBeforeRemove: function (e, page) {
      console.log("pageBeforeRemove", page);
    }
  },
  id: '82c2af151a',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Back</span></a></div><div class=title>Return To Rack</div></div></div><div class=page-content><div class="list media-list"><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.returns, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers._partial.call(ctx_2, "return-item", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    