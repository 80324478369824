
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      Template7.registerPartial('order-product', '<li class="swipeout">    <div class="swipeout-content">      <div class="item-content">        <div class="item-media">          <!-- prettier-ignore -->          <img src="https://cdn.infiniteshopping.com/cache/175x175-0/static/{{image}}?1" width="80" />        </div>        <div class="item-inner">          <div class="item-title-row">            <div class="item-title">{{ title }}</div>            <div class="item-after">{{ qty }}</div>          </div>          <div class="item-subtitle">Order ID: {{ order_id }}</div>          <div class="item-subtitle">            Marketplace OID:            {{ marketplace_order_id }}          </div>          <div class="item-subtitle">            SKU: {{ sku }} PID:            {{ product_id }}          </div>          <div class="item-text" style="overflow: visible;">            <ul>              {{#each expiryInfo}}              <li>Qty: {{ qty }} - Expiry: {{ expiry }}</li>              {{/each}}            </ul>          </div>        </div>      </div>    </div>    <div class="swipeout-actions-left">      <a class="color-purple swipeout-overswipe" @click="ShowProductInfo">Product Info</a>    </div>    <!-- <div class="swipeout-actions-right">      s      <a class="color-green swipeout-overswipe" @click="TakePhoto({{@index}})"        >Take Photo</a      >    </div> -->  </li>  <!-- <li>    <div class="thumbImages">      {{#each cameraImages}}      <img        @taphold.native="DeleteImage({{ ../@index}},{{ @index }})"        @click="ShowLargeImage({{ ../@index}},{{ @index }})"        style="width:100px;"        src="{{this}}"        alt=""      />      {{/each}}    </div>  </li> -->');
  
      import util from "util";
export default {
  // Lifecycle Hooks
  beforeCreate() {
    console.log("componentBeforeCreate", this);
  },

  created() {
    console.log("componentCreated", this);
  },

  beforeMount() {
    console.log("componentBeforeMount", this);
  },

  mounted() {
    console.log("componentMounted", this);
    const self = this;
    const app = self.$app;
    const $ = self.$$;
    const router = self.$router;
  },

  beforeDestroy() {
    console.log("componentBeforeDestroy", this);
  },

  destroyed() {
    console.log("componentDestroyed", this);
  },

  // Component Data
  data: function () {
    // Must return an object
    return {
      orderProducts: [],
      order_id_text: "",
      cameraImages: [],
      photo_iid: 0
    };
  },
  // Component Methods
  methods: {
    openAlert: function () {
      var self = this;
      self.$app.dialog.alert("Hello World");
    },
    ShowProductInfo: function () {},
    ShowLargeImage: function (id) {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$router;
      app.photoBrowser.destroy();
      var myPhotoBrowserPopup = app.photoBrowser.create({
        photos: self.cameraImages,
        type: "popup"
      });
      myPhotoBrowserPopup.open(id);
    },
    DeleteImage: function (id) {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$router;
      app.dialog.confirm("Delete this image?", "Warning", function () {
        self.cameraImages.splice(id, 1);
        self.$setState({
          cameraImages: self.cameraImages
        });
      }, function () {});
    },
    SavePhotos: function () {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$routers;

      if (!self.cameraImages.length) {
        app.dialog.alert("No photos to save :)", "Info");
        return;
      }

      async function UploadFile(fileData, newFileName) {
        var reader = new FileReader();

        reader.onloadend = function () {
          // Create a blob based on the FileReader "result", which we asked to be retrieved as an ArrayBuffer
          var blob = new Blob([new Uint8Array(this.result)], {
            type: "image/jpeg"
          });
          let date = new Date().toISOString().substring(0, 10);
          var oReq = new XMLHttpRequest();
          var fileName64 = encodeURIComponent(window.btoa(newFileName));
          console.log("`http://192.168.0.130/savelabelimage.php?date=${date}&filename=${fileName64}` :", `http://192.168.0.130/savelabelimage.php?date=${date}&filename=${fileName64}`);
          oReq.open("POST", `http://192.168.0.130/savelabelimage.php?date=${date}&filename=${fileName64}`, true);

          oReq.onload = function (oEvent) {
            console.log("oEvent :", oEvent); // all done!
          }; // Pass the blob in to XHR's send method


          oReq.send(blob);
        }; // Read the file as an ArrayBuffer


        reader.readAsArrayBuffer(fileData);
      }

      let dialog = app.dialog.progress("Saving photos on server");
      let fileNameOrderInfo = `${self.orderProducts[0].alt_trackingno}_${self.orderProducts[0].marketplace}_${self.orderProducts[0].order_id}_${self.orderProducts[0].marketplace_order_id}`;
      let fileNameProductsInfo = [];
      self.orderProducts.forEach((product, pid) => {
        let qty = product.expiryInfo.reduce((previous, current) => {
          let sum = previous + current.qty;
          return sum;
        }, 0);
        console.log("qty :", qty);
        fileNameProductsInfo.push(`${product.sku}_(${qty})`);
      });
      let fileNamePrefix = `${fileNameOrderInfo}___${fileNameProductsInfo.join("___")}`;

      window.resolveLocalFileSystemURL[util.promisify.custom] = imageData => {
        return new Promise((resolve, reject) => {
          window.resolveLocalFileSystemURL(imageData, resolve, reject);
        });
      };

      const resolveLocalFileSystemURL = util.promisify(window.resolveLocalFileSystemURL);
      let promises = self.cameraImages.map(async (imageData, iid) => {
        console.log("imageData :", imageData);

        try {
          let entry = await resolveLocalFileSystemURL(imageData);
          console.log("entry:", entry);
          let storageLocation = "file:///storage/emulated/0/";
          let newFileName = `${fileNamePrefix}-${iid + self.photo_iid + 1}.jpg`; // prettier-ignore

          let saveFolderName = "Documents/" + new Date().toISOString().substring(0, 10);
          let fileSystem = await resolveLocalFileSystemURL(cordova.file.externalRootDirectory);

          fileSystem.getDirectory[util.promisify.custom] = (saveFolderName, params) => {
            return new Promise((resolve, reject) => {
              fileSystem.getDirectory(saveFolderName, params, resolve, reject);
            });
          };

          const getDirectory = util.promisify(fileSystem.getDirectory);
          let directory = await getDirectory(saveFolderName, {
            create: true,
            exclusive: false
          });
          console.log("directory :", directory);

          entry.moveTo[util.promisify.custom] = (directory, newFileName) => {
            return new Promise((resolve, reject) => {
              entry.moveTo(directory, newFileName, resolve, reject);
            });
          };

          await entry.moveTo(directory, newFileName);
          let remoteFolderName = "Documents/" + new Date().toISOString().substring(0, 10);
          fileSystem = await resolveLocalFileSystemURL(cordova.file.externalRootDirectory + remoteFolderName);

          fileSystem.getFile[util.promisify.custom] = (newFileName, params) => {
            return new Promise((resolve, reject) => {
              fileSystem.getFile(newFileName, params, resolve, reject);
            });
          };

          const getFile = util.promisify(fileSystem.getFile);
          let fileEntry = await getFile(newFileName, {
            create: true,
            exclusive: false
          });
          console.log("fileEntry :", fileEntry);

          fileEntry.file[util.promisify.custom] = () => {
            return new Promise((resolve, reject) => {
              fileEntry.file(resolve, reject);
            });
          };

          console.log("fileEntry I:", fileEntry);
          const getFileData = util.promisify(fileEntry.file);
          let fileData = await getFileData();
          console.log("fileData I:", fileData);
          await UploadFile(fileData, newFileName);
        } catch (error) {
          app.dialog.close();
          app.dialog.alert("AJAX Error: " + error);
          console.log("error :", error);
        }
      });
      Promise.all(promises).then(res => {
        console.log("res :", res);
        self.$setState({
          orderProducts: [],
          order_id_text: "",
          cameraImages: [],
          photo_iid: 0
        });
      }).catch(err => {
        console.log("error :", error);
      });
      app.dialog.close();
      app.dialog.alert("Images Saved/Uploaded..", "Success");
    },
    TakePhoto: function () {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$router;
      console.log("self.orderProducts.length :", self.orderProducts.length);
      console.log("self.orderProducts :", self.orderProducts);

      if (!self.orderProducts.length) {
        app.dialog.alert("Scan a shipping label first :)", "Info");
        return;
      } else {
        console.log("navigator.camera.DestinationType.imageURI :", navigator.camera);
        navigator.camera.getPicture(function (imageURI) {
          self.cameraImages.push(imageURI);
          self.$setState({
            cameraImages: self.cameraImages
          });
          self.$tick(() => {
            // DOM updated
            self.TakePhoto();
          });
        }, function (error) {
          if (error.trim() != "No Image Selected") {
            app.dialog.alert("Failed because: " + error);
          }
        }, {
          quality: 70,
          destinationType: navigator.camera.DestinationType.FILE_URI,
          correctOrientation: true,
          saveToPhotoAlbum: false,
          mediaType: navigator.camera.MediaType.PICTURE,
          encodingType: navigator.camera.EncodingType.JPEG,
          sourceType: navigator.camera.PictureSourceType.CAMERA,
          allowEdit: false,
          cameraDirection: navigator.camera.Direction.BACK
        });
      }
    },
    GetProductInfo: function (postdata) {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$router;
      app.dialog.preloader(`Fetching Data`);
      app.request.promise({
        url: app.data.apiUrl,
        data: postdata,
        method: "POST",
        dataType: "json",
        headers: {
          Authorization: app.data.ifs_token
        },
        timeout: app.data.defaultTimeout
      }).then(function ({
        data,
        xhr,
        status
      }) {
        console.log(data);
        let orderProducts = data.json.map(each => {
          each.expiryInfo = each.expiry_info.split(",").map(item => {
            // TODO if arr undefined means item not picked...
            let arr = item.split("|");
            return {
              qty: arr[0],
              expiry: arr[1]
            };
          });
          return each;
        });
        console.log("orderProducts :", orderProducts);
        app.dialog.close();

        if (data.result == 1) {
          self.$setState({
            orderProducts: orderProducts,
            order_id_text: `Order Id: ${data.json[0].order_id}`
          });
          self.$tick();
        } else {
          app.dialog.alert(`Error while fetching data. <br> Error 1: ${data.error}`, "Error");
        }
      }).catch(function (error) {
        app.dialog.close();
        app.dialog.alert(`Error while fetching data. <br> Error 2: ${error.message}`, "Error");
      });
    },
    ShowScanner: function () {
      const self = this;
      const app = self.$app;
      const $ = self.$$;
      const router = self.$router;
      app.data.isBarcodeScannerOn = true;
      cordova.plugins.barcodeScanner.scan(function (result) {
        if (result.cancelled !== true) {
          app.data.isBarcodeScannerOn = false;
        }

        let scan_result = result.text.toString().trim();
        let search_by;
        let search_val;

        if (result.format.includes("DATA_MATRIX")) {
          search_val = scan_result.split("-")[1].split("_")[0];
          search_by = "shipment_id";
        } else if (result.format.includes("CODE_128")) {
          search_val = scan_result;
          search_by = "tracking_no";
        } else if (result.format.includes("CODE_39")) {
          search_val = scan_result;
          search_by = "tracking_no";
        } else {
          app.dialog.alert("Invalid Scan...", "Error");
          return;
        } // get local images


        let postdata_photos = {
          searchterm: search_val,
          calendarrange: ""
        };
        let postdata = {
          remoteSection: "ims",
          w: "GetShipmentProductsInfo",
          search_val,
          search_by
        };
        self.$setState({
          photo_iid: 0
        });
        self.$tick(() => {
          app.request.promise({
            url: "http://192.168.0.130/getlabelphotos.php",
            data: postdata_photos,
            method: "POST",
            dataType: "json",
            timeout: app.data.defaultTimeout
          }).then(function ({
            data,
            xhr,
            status
          }) {
            app.dialog.close();

            if (data.result == 1) {
              if (data.label_images.length) {
                app.dialog.confirm(`${data.label_images.length} Photos already taken. Take more photos?`, "Warning", function () {
                  self.$setState({
                    photo_iid: data.label_images.length
                  });
                  self.$tick(() => {
                    self.GetProductInfo(postdata);
                  });
                }, function () {
                  self.$setState({
                    photo_iid: 0
                  });
                  return;
                });
              }
            } else {
              self.$tick(() => {
                self.GetProductInfo(postdata);
              });
            }
          }).catch(function (error) {
            app.dialog.close();
            app.dialog.alert(`Error while fetching data. <br> Error 2: ${error.message}`, "Error");
          });
        });
      }, function (error) {
        // app.data.isBarcodeScannerOn = false;
        app.dialog.alert("Scanning failed: " + error);
      }, {
        preferFrontCamera: false,
        // iOS and Android
        showFlipCameraButton: false,
        // iOS and Android
        showTorchButton: true,
        // iOS and Android
        torchOn: false,
        // Android, launch with the torch switched on (if available)
        saveHistory: false,
        // Android, save scan history (default false)
        prompt: "Place a barcode inside the scan area",
        // Android
        resultDisplayDuration: 500,
        // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
        formats: "CODE_128",
        // default: all but PDF_417 and
        orientation: "portrait",
        // Android only (portrait|landscape), default unset so it rotates with the device
        disableAnimations: true,
        // iOS
        disableSuccessBeep: true,
        // iOS and Android
        continuousMode: false // Android

      });
    }
  },
  // Page Events
  on: {
    pageMounted: function (e, page) {
      console.log("pageMounted", page);
    },
    pageInit: function (e, page) {
      console.log("pageInit", page);
    },
    pageBeforeIn: function (e, page) {
      console.log("pageBeforeIn", page);
    },
    pageAfterIn: function (e, page) {
      console.log("pageAfterIn", page);
    },
    pageBeforeOut: function (e, page) {
      console.log("pageBeforeOut", page);
    },
    pageAfterOut: function (e, page) {
      console.log("pageAfterOut", page);
    },
    pageBeforeRemove: function (e, page) {
      console.log("pageBeforeRemove", page);
    }
  },
  id: '409c6d5a49',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=label-photos><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Back</span></a></div><div class=title>Label Photos - ';
      r += c(ctx_1.order_id_text, ctx_1);
      r += '</div><div class=right><a class="link color-green" @click=SavePhotos>Save</a></div></div></div><div class="fab fab-extended fab-right-bottom"><a class="color-green margin-bottom" @click=SavePhotos><i class="icon f7-icons size-32">cloud_upload</i> </a><a class="fab color-purple" @click=ShowScanner><i class="icon f7-icons size-32">qrcode</i></a></div><div class="fab fab-extended fab-center-bottom color-red"><a @click=TakePhoto><i class="icon f7-icons size-32">camera_round_fill</i></a></div><div class=page-content><div class="list media-list"><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.orderProducts, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers._partial.call(ctx_2, "order-product", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div><div class=block><div class=thumbImages>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.cameraImages, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' <img @taphold.native="DeleteImage(';
          r += c(data_2 && data_2.index, ctx_2);
          r += ')" @click="ShowLargeImage(';
          r += c(data_2 && data_2.index, ctx_2);
          r += ')" style="width: 100px" src=';
          r += c(ctx_2, ctx_2);
          r += ' alt=""> ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    